import { useQuery, UseQueryResult } from 'react-query';
import { TargetCandidates } from '../../services/dataService';
import { cases } from '../../services/nestApiService';
import { CaseType } from '../../features/cases/CaseNew';

export const useTargetCandidatesQuery = (
    caseId: string,
    targetId: string,
    caseType: CaseType | null,
): UseQueryResult<TargetCandidates, string> =>
    useQuery(
        ['targetCandidates', caseId, targetId, caseType],
        () => cases.getCandidates(caseId, targetId, caseType),
        {
            enabled: caseType !== null,
            refetchOnWindowFocus: false,
        },
    );
