import { TargetReportSourceType } from '@indicium/common';
import { CaseType } from '../features/cases/CaseNew/CaseNew.types';

export const allowedSourcesByCaseType = (
    caseType: CaseType | null,
): Array<TargetReportSourceType | string> => {
    if (caseType === null) {
        return [];
    }

    /*
     * base for when caseType === 'essential'.
     * Lexisnexis is also allowed for essential... but it is not a pure source type.
     */
    const allowedSources: TargetReportSourceType[] = [
        'userInput',
        'google',
        'pipl',
        'shadowdragon',
    ];

    if (caseType === CaseType.Plus) {
        allowedSources.push('complianceCatalyst');
    }

    if (caseType === CaseType.Complete) {
        allowedSources.push('orbis', 'icijLeaks', 'companyhouse', 'zefix');
    }

    return allowedSources;
};
