import { useQuery, UseQueryResult } from 'react-query';
import { UserData } from 'src/types/UserData';
import { users } from '../../services/nestApiService';

export const useUsersQuery = (
    customerId?: string,
): UseQueryResult<UserData[], string> =>
    useQuery('users', () => users.getAll(customerId), {
        refetchOnWindowFocus: false,
    });

export const useCurrentUserQuery = (
    userId: string | undefined,
): UseQueryResult<UserData> => {
    return useQuery(['currentUser'], async () => users.getUser(userId ?? ''), {
        refetchOnWindowFocus: false,
        enabled: !!userId,
    });
};
