import { Button, Card, Headline, Input, Paragraph, RadioGroup } from '_atoms';
import { useCustomerQuery } from '_queries';
import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserActionTypes, useUserState } from '../../context/User';
import { useUserQuota } from '../../hooks/useUserQuota';
import { routes } from '../../routes';
import {
    setPreferredMFA,
    signout,
    USER_GROUP_ADMINISTRATORS,
    USER_GROUP_SUPER_USERS,
} from '../../services/authenticationService';
import { DefaultPageLayout } from '../../components/Layout/DefaultPageLayout';
import { useToast } from '../../context/Toast';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateCurrentUserMutation } from '../../hooks/queries/useUpdateUserMutation';
import { EmailPreference, UpdateUserData } from '../../types/UserData';
import { useCurrentUserQuery } from '../../hooks/queries/useUsersQuery';

export const EditProfile: FC = () => {
    const { t } = useTranslation();
    const [{ user, initialRoute, userRoles }, dispatch] = useUserState();
    const [{ customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId, {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const toast = useToast();
    const {
        isLoading,
        isSuccess,
        isError,
        mutate,
        reset: resetMutation,
    } = useUpdateCurrentUserMutation();

    const { data: userData } = useCurrentUserQuery(user?.username);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { isDirty, isValid },
    } = useForm<UpdateUserData>();

    useEffect(() => {
        if (userData) {
            reset({
                firstname: userData.firstname,
                lastname: userData.lastname,
                emailPreference: userData.emailPreference,
            });
        }
    }, [reset, userData]);

    const showToast = useCallback(
        (text: string, style: 'confirmation' | 'error') => {
            toast({
                text,
                style: style,
                ms: 5000,
            });
        },
        [toast],
    );

    useEffect(() => {
        if (isSuccess) {
            showToast(t('toasts.userUpdated'), 'confirmation');
            resetMutation();
        }
        if (isError) {
            showToast(t('toasts.userUpdateFailed'), 'error');
        }
    }, [isSuccess, t, toast, resetMutation, showToast, isError]);

    const { quota } = useUserQuota();

    if (!user) {
        return null;
    }

    const onSubmit = async (body: UpdateUserData) => {
        mutate({ userId: user.username, body });
    };

    const isAdmin =
        userRoles.includes(USER_GROUP_ADMINISTRATORS) ||
        userRoles.includes(USER_GROUP_SUPER_USERS);
    const isOwner = isAdmin && user.attributes.isCustomerOwner === 'True';

    const userRole = isOwner
        ? t('CustomerOwner')
        : isAdmin
        ? t('CustomerAdmin')
        : t('CustomerUser');

    return (
        <DefaultPageLayout title={t('editProfile')}>
            <Card className="p-9 rounded-2xl space-y-5 *:max-w-150 *:mx-auto">
                <Paragraph>
                    <>
                        <strong>{`${t('email')}: `}</strong>
                        {userData?.email}
                    </>
                </Paragraph>
                <Paragraph>
                    {customer && (
                        <>
                            <strong>{`${t('organization')}: `}</strong>
                            {customer.name}
                        </>
                    )}
                </Paragraph>
                <Paragraph>
                    <>
                        <strong>{`${t('privilegeLevel')}: `}</strong>
                        {userRole}
                    </>
                </Paragraph>
                <Paragraph>
                    {quota && (
                        <>
                            <strong>{`${t('quota')}: `}</strong>
                            {`${quota.used}/${
                                quota.total === Infinity ? '∞' : quota.total
                            }`}
                        </>
                    )}
                </Paragraph>
                <br />
                <div className="space-y-6">
                    <Headline Level="h4">
                        {t('authenticationSection.headline')}
                    </Headline>
                    <div className="flex gap-4">
                        <Link to={routes.changePassword.path}>
                            <Button size="small">{t('changePassword')}</Button>
                        </Link>
                        {user.preferredMFA === 'NOMFA' ? (
                            <Link to={routes.setupMfa.path}>
                                <Button size="small" type="button">
                                    {t('authenticationSection.enableMfa')}
                                </Button>
                            </Link>
                        ) : (
                            <Button
                                type="button"
                                size="small"
                                onClick={async () => {
                                    await setPreferredMFA('NOMFA');
                                    await signout();
                                    dispatch({
                                        type: UserActionTypes.SET,
                                        user: null,
                                    });
                                    showToast(
                                        t('toasts.mfaDisabled'),
                                        'confirmation',
                                    );
                                }}
                            >
                                {t('authenticationSection.disableMfa')}
                            </Button>
                        )}
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
                    <Headline Level={'h4'}>{t('editUserData')}</Headline>

                    <Input
                        label={t('firstname')}
                        {...register('firstname', { required: true })}
                        placeholder={t('firstname')}
                    />

                    <Input
                        label={t('lastname')}
                        {...register('lastname', { required: true })}
                        placeholder={t('lastname')}
                    />

                    <Controller
                        name="emailPreference"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <RadioGroup
                                groupLabel={{
                                    label: t('emailPreferences.groupLabel'),
                                    tooltip: t(
                                        'emailPreferences.groupLabelTooltip',
                                    ),
                                }}
                                className="mt-2"
                                direction="vertical"
                                options={Object.values(EmailPreference)
                                    .filter(
                                        (option) =>
                                            isAdmin ||
                                            option !== EmailPreference.AllInOrg,
                                    )
                                    .map((option) => ({
                                        label: t(`emailPreferences.${option}`),
                                        value: option,
                                        tooltip: t(
                                            `emailPreferences.${option}Tooltip`,
                                        ),
                                    }))}
                                selected={value}
                                onSelect={onChange}
                            />
                        )}
                    />

                    <div className="flex items-center justify-between gap-6 pt-4">
                        <Link to={initialRoute}>
                            <Button level="primaryGhost">
                                {t('backToOverview')}
                            </Button>
                        </Link>

                        <Button
                            type="submit"
                            disabled={isLoading || !isDirty || !isValid}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </form>
            </Card>
        </DefaultPageLayout>
    );
};
