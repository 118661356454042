import React, { FC, forwardRef, HTMLProps } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { de, enUS, enGB } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { MdOutlineCalendarMonth } from 'react-icons/md';

const CustomInput = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
    ({ value, onClick, placeholder }, ref) => (
        <button
            className="flex gap-2 items-center border border-neutral-400 focus:border-primary-4 hover:border-primary-4 w-full rounded-md transition-all px-4 py-3.5 pr-9 text-left text-base bg-neutral-50 text-neutral-500 font-normal focus:ring-blue-500"
            type="button"
            onClick={onClick}
            ref={ref}
        >
            <MdOutlineCalendarMonth size={18} />
            <span>{value || placeholder}</span>
        </button>
    ),
);
CustomInput.displayName = 'CustomInput';

const getLocale = (language: string) => {
    if (language === 'en-GB') {
        return enGB;
    }

    if (language === 'en-US' || language.startsWith('en')) {
        return enUS;
    }

    return de;
};

interface DatePickerV2Props extends ReactDatePickerProps {
    label: string;
}

export const DatePickerV2: FC<DatePickerV2Props> = ({
    label,
    onChange,
    dateFormat = 'dd-MM-yyyy',
    showYearDropdown = true,
    yearDropdownItemNumber = 75,
    scrollableYearDropdown = true,
    ...rest
}: DatePickerV2Props) => {
    const { i18n } = useTranslation();

    const locale = getLocale(i18n.language);

    return (
        <div className={label ? 'flex flex-col gap-1' : ''}>
            {label && (
                <span className="font-jost font-bold text-neutral-500 text-sm">
                    {label}
                </span>
            )}
            <ReactDatePicker
                onChange={onChange}
                dateFormat={dateFormat}
                customInput={<CustomInput />}
                locale={locale}
                popperClassName="z-30"
                showYearDropdown={showYearDropdown}
                yearDropdownItemNumber={yearDropdownItemNumber}
                scrollableYearDropdown={scrollableYearDropdown}
                {...rest}
            />
        </div>
    );
};
