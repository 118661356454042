import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, SearchInput, SocialMediaImage, Tabs } from '_molecules';
import { SearchFilterValue } from '_types';
import { getCdnUrl, nonProdDataTestId } from '_utils';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';
import { SocialAccountConnectionArticle } from '../../../services/searchService';

type SocialMediaContactsFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

type AccountCount = {
    source: string;
    counts: Record<string, number>;
    total: number;
};

export const trimConnectionUserName = (username: string): string => {
    if (!username) {
        return '';
    }
    const facebookQueryStringsIndex1 = username.indexOf('&');
    const facebookQueryStringsIndex2 = username.indexOf('?');
    const facebookQueryStringsIndex =
        facebookQueryStringsIndex1 !== -1 && facebookQueryStringsIndex2 !== -1
            ? Math.min(facebookQueryStringsIndex1, facebookQueryStringsIndex2)
            : Math.max(facebookQueryStringsIndex1, facebookQueryStringsIndex2);
    return facebookQueryStringsIndex !== -1
        ? username.slice(0, facebookQueryStringsIndex).trim()
        : username.trim();
};

export const SocialMediaContacts: FC = () => {
    const { t } = useTranslation();

    const [accountCounts, setAccountCounts] = useState<Array<AccountCount>>([]);

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<SocialMediaContactsFilters>({
            filters: [],
        });

    const { data, isLoading, isError } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'socialMediaConnection',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
        pageSize: 600,
    });

    const { onFilterChange } = searchInputProps;

    const socialMediaConnections = useMemo(
        () =>
            (data?.pages.flatMap((page) => page.items) ??
                []) as SocialAccountConnectionArticle[],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const [selectedAccountType, setSelectedAccountType] = useState<string>('');
    const [selectedConnectionType, setSelectedConnectionType] =
        useState<string>('');

    const count = articleMeta?.count;

    const countsPerAccount = useMemo(() => {
        return Object.keys(count ?? {}).map((accountType) => ({
            source: accountType,
            counts: count?.[accountType] as Record<string, number>,
            total: (Object.values(count?.[accountType]) as number[]).reduce(
                (total, next) => total + next,
                0,
            ),
        }));
    }, [count]);

    useEffect(() => {
        setAccountCounts(countsPerAccount);
    }, [countsPerAccount]);

    useEffect(() => {
        const firstAccountType = countsPerAccount.at(0)?.source;
        const firstConnectionType = Object.keys(
            countsPerAccount.find(
                (accountCount) => accountCount.source === firstAccountType,
            )?.counts ?? {},
        ).at(0);

        setSelectedAccountType((prev) => prev ?? firstAccountType ?? '');
        setSelectedConnectionType((prev) => prev ?? firstConnectionType ?? '');
    }, [countsPerAccount]);

    useEffect(() => {
        onFilterChange({
            ...(selectedAccountType && {
                accountType: {
                    type: 'query',
                    query: 'terms',
                    value: [selectedAccountType],
                },
            }),
            ...(selectedConnectionType && {
                connectionType: {
                    type: 'query',
                    query: 'terms',
                    value: [selectedConnectionType],
                },
            }),
        });
    }, [selectedAccountType, selectedConnectionType, onFilterChange]);

    const connectionTypeTabs = accountCounts?.length
        ? Object.entries(
              (
                  accountCounts?.find(
                      (accountCount) =>
                          accountCount.source === selectedAccountType,
                  ) || accountCounts[0]
              ).counts,
          )
        : [];

    return (
        <div>
            <SearchInput
                {...searchInputProps}
                className="mb-5"
                hideFilters={true}
            >
                {renderedFilters}
            </SearchInput>

            {accountCounts && accountCounts?.length ? (
                <>
                    <Tabs
                        tabTitles={accountCounts.map(
                            (actionCount) =>
                                `${actionCount.source} (${Object.values(
                                    actionCount.counts,
                                ).reduce((sum, count) => sum + count, 0)})`,
                        )}
                        onChange={(index) => {
                            const newSelectedAccountType =
                                accountCounts[index].source;
                            setSelectedAccountType(newSelectedAccountType);
                            const selectedAccountCounts = Object.entries(
                                accountCounts.find(
                                    (ac) =>
                                        ac.source === newSelectedAccountType,
                                )?.counts || accountCounts[0].counts,
                            );
                            const newSelectedConnectionType =
                                (selectedAccountCounts.find(
                                    ([_key, value]) => value > 0,
                                ) || selectedAccountCounts[0])[0];
                            setSelectedConnectionType(
                                newSelectedConnectionType,
                            );
                        }}
                    />
                    <Tabs
                        tabTitles={connectionTypeTabs.map(([key, value]) => {
                            return `${key} (${value})`;
                        })}
                        onChange={(index) => {
                            setSelectedConnectionType(
                                connectionTypeTabs[index][0],
                            );
                        }}
                        selectedIndex={connectionTypeTabs.findIndex(
                            ([key, _]) => key === selectedConnectionType,
                        )}
                    />
                </>
            ) : null}
            <div
                className="relative z-10"
                data-testid={nonProdDataTestId('socialmediaposts')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : socialMediaConnections?.length ? (
                    <Fragment>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-8 py-6 items-center relative my-3 w-full">
                            {socialMediaConnections
                                .sort((a, b) =>
                                    a.connectionUsername < b.connectionUsername
                                        ? -1
                                        : 1,
                                )
                                .map((item) => (
                                    <a
                                        key={item.id}
                                        className="flex items-center text-sm text-primary-4 hover:text-primary-3 font-normal focus:outline-none"
                                        href={item.connectionProfileUrl}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <SocialMediaImage
                                            size="small"
                                            zoomOnHover={true}
                                            src={getCdnUrl(
                                                item.connectionProfileImageCdnUrl,
                                            )}
                                        />
                                        <span className="truncate">
                                            {trimConnectionUserName(
                                                item.connectionUsername ||
                                                    item.connectionProfileName,
                                            )}
                                        </span>
                                    </a>
                                ))}
                        </div>
                    </Fragment>
                ) : (
                    <Alert alertType="info" message={t('noData')} />
                )}
            </div>
        </div>
    );
};
