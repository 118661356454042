import { FC } from 'react';
import { DonutPieChart } from '_organisms/Charts/DonutPieChart';
import { DonutStatistic } from '../../types';
import { CvStatisticQuestionMark } from './HelperComponents';
import { generateColors } from '../helpers';

export interface DonutChartStatisticProps
    extends Omit<DonutStatistic, 'componentType'> {
    radius?: number;
}

export const CvStatisticChart: FC<DonutChartStatisticProps> = ({
    data,
    radius = 32,
}) => {
    const { isMissingData, data: _data } = data;

    const colors = generateColors(_data.length);

    return (
        <>
            {isMissingData ? (
                <CvStatisticQuestionMark />
            ) : (
                <DonutPieChart data={_data} colors={colors} radius={radius} />
            )}
        </>
    );
};
