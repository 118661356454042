import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import classnames from 'classnames';
import { IconType } from 'react-icons/lib';

type ButtonLevel =
    | 'primary'
    | 'primaryGhost'
    | 'secondary'
    | 'secondaryGhost'
    | 'error'
    | 'errorGhost'
    | 'dark'
    | 'darkGhost'
    | 'custom';

export interface ButtonProps
    extends DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    level?: ButtonLevel;
    children?: ReactNode;
    icon?: IconType;
    iconIsRight?: boolean;
    size?: 'default' | 'small';
}

const styles: Record<ButtonLevel | 'custom', string> = {
    primary:
        'border-transparent shadow-sm text-font-light bg-primary-4 hover:bg-primary-3 disabled:hover:bg-primary-4',
    secondary:
        'border-transparent shadow-sm text-font-light bg-contrast-2 hover:bg-contrast-1 disabled:hover:bg-contrast-2',
    primaryGhost:
        'border-primary-4 shadow-sm text-primary-4 bg-transparent hover:border-primary-3 hover:text-primary-3 disabled:hover:text-primary-4',
    secondaryGhost:
        'border-contrast-2 shadow-sm text-contrast-2 bg-transparent hover:border-contrast-1 hover:text-contrast-1 disabled:hover:text-contrast-2',
    error: 'border-transparent shadow-sm text-font-light bg-error-2 hover:bg-error-1 disabled:hover:bg-error-2',
    errorGhost:
        'border-error-2 shadow-sm text-error-2 bg-transparent hover:border-error-1 hover:text-error-1 disabled:hover:text-error-2',
    dark: 'border-transparent shadow-sm text-font-light bg-primary-1 hover:bg-primary-2 disabled:hover:bg-primary-1',
    darkGhost:
        'border-primary-1 shadow-sm text-primary-1 bg-transparent hover:border-primary-2 hover:text-primary-2 disabled:hover:text-primary-1',
    custom: 'border-transparent',
};

const iconSize = 22;

export const Button: FC<ButtonProps> = ({
    level = 'primary',
    className,
    children,
    icon: Icon,
    iconIsRight = false,
    ...rest
}: ButtonProps) => {
    return (
        <button
            className={classnames(
                'inline-flex items-center justify-center border-2 text-base font-bold rounded-full focus:outline-none disabled:opacity-50 disabled:cursor-default transition-colors relative',
                styles[level],
                className,
                {
                    'p-3': !!Icon && !children,
                    'px-4 py-3.5': !Icon || (!!Icon && children),
                    'pl-10': !!Icon && children && !iconIsRight,
                    'pr-10': !!Icon && children && iconIsRight,
                },
            )}
            {...rest}
        >
            {Icon && children && (
                <Icon
                    size={iconSize}
                    className={classnames(
                        'absolute top-1/2 -translate-y-1/2',
                        !iconIsRight && 'left-3',
                        iconIsRight && 'right-3',
                    )}
                />
            )}
            {Icon && !children && <Icon size={iconSize} className="shrink-0" />}
            {children}
        </button>
    );
};
