import {
    differenceInDays,
    format,
    isBefore,
    isValid,
    parse,
    toDate,
} from 'date-fns';
import {
    LegacyTargetCandidateInfoItem,
    TargetReportSourceType,
} from '@indicium/common';
import config from '../backendConfig.json';
import i18n from '../i18n';
import {
    TargetCandidatesGroup,
    TargetGoogleCandidatesGroup,
    TargetProfileData,
} from '_types';
import { TargetPersonSelectionState } from '_enums';
import classNames from 'classnames';
import { TargetCandidates } from './dataService';
import { COMPANY_SUFFIXES } from './companySuffixes';
import { ContentType } from '_molecules';
import * as Yup from 'yup';
import { allowedSourcesByCaseType } from '../utils/caseType-utils';
import { CaseType } from '../features/cases/CaseNew';

const CDN_URL = 'https://' + config.DomainNameHttp.replace('api.', 'cdn.');

export function isNeitherNullNorUndefined<T>(
    value: T,
): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export const parseRem: (value: string) => number = (value) =>
    value.indexOf('rem') ? parseFloat(value.split('rem')[0]) * 16 : 0;

export const mapSourceToText = (source: string): string =>
    `sourceName.${source.toLowerCase()}`;

export const mapSourcesToText: (value: TargetReportSourceType[]) => string = (
    value,
) => {
    const sources = value.map((v) => i18n.t(mapSourceToText(v)));
    return sources.join(', ');
};

type Umlauts = 'Ä' | 'ä' | 'Ö' | 'ö' | 'Ü' | 'ü' | 'ß';

const UmlautsMap: Record<Umlauts, string> = {
    Ä: 'ae',
    ä: 'ae',
    Ö: 'oe',
    ö: 'oe',
    Ü: 'ue',
    ü: 'ue',
    ß: 'ss',
} as const;

export const replaceUmlauts = (value: string): string =>
    value.replace(/Ä|ä|Ö|ö|Ü|ü|ß/gi, (match) => UmlautsMap[match as Umlauts]);

export type BirthdateRange = {
    value: {
        end: string;
        start: string;
    };
    sources: TargetReportSourceType[];
};

export const formatDate = (date: string | Date): string => {
    try {
        if (
            typeof date === 'string' &&
            isValid(parse(date, 'dd.MM.yyyy', new Date()))
        ) {
            return date;
        }

        return format(
            typeof date === 'string' ? new Date(date) : date,
            'dd.MM.yyyy',
        );
    } catch (e) {
        return '';
    }
};

export const formatDateTime = (date: string | Date): string => {
    try {
        return format(
            typeof date === 'string' ? new Date(date) : date,
            'dd.MM.yyyy, HH:mm',
        );
    } catch (e) {
        return '';
    }
};

export const transformBirthdayRangeToString = (
    data: BirthdateRange[] = [],
): ContentType[] =>
    data.map(({ value: { start, end }, sources }) => {
        return {
            value:
                start === end
                    ? formatDate(start)
                    : checkBirthdateRange(start, end),
            sources,
            additionalValue:
                start === end
                    ? ' (' +
                      i18n.t('yearsOld', { age: calculateAge(start) }) +
                      ')'
                    : undefined,
        };
    });

const checkBirthdateRange = (start: string, end: string): string => {
    // new Date() doesn't support the date format dd.MM.yyyy
    const potentiallyParsedStartDate = parse(start, 'dd.MM.yyyy', new Date());
    const startDate = isValid(potentiallyParsedStartDate)
        ? toDate(potentiallyParsedStartDate)
        : new Date(start);

    const potentiallyParsedEndDate = parse(end, 'dd.MM.yyyy', new Date());
    const endDate = isValid(potentiallyParsedEndDate)
        ? toDate(potentiallyParsedEndDate)
        : new Date(end);

    if (
        format(startDate, 'dd.MM') === '01.01' &&
        format(endDate, 'dd.MM') === '31.12' &&
        format(startDate, 'yyyy') === format(endDate, 'yyyy')
    ) {
        return format(endDate, 'yyyy');
    }

    return [formatDate(startDate), formatDate(endDate)].join(' - ');
};

const calculateAge = (birthday: string): string => {
    const today = new Date();
    const potentiallyParsedDate = parse(birthday, 'dd.MM.yyyy', new Date());
    const birthDate = isValid(potentiallyParsedDate)
        ? toDate(potentiallyParsedDate)
        : new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() <= birthDate.getDate())) {
        age--;
    }
    return age.toString();
};

/**
 * Takes url or digitalclues asset filepath.
 * Returns url to the image, running filepaths through assetproxy if needed
 */
export const proxifyDigitalcluesAssetFilepathIfNeeded = (
    dcFilepathOrUrl: string,
    accessToken: string,
): string => {
    if (!dcFilepathOrUrl) {
        return '';
    }
    // As long as it starts with https:// we can use that directly as the proper url
    if (dcFilepathOrUrl.startsWith('https://')) {
        return dcFilepathOrUrl;
    }
    // Otherwise we have to assume it is a filepath from DC which needs to be proxied because of DC is forcing us to use a VPC to access their cached media assets. Additionally media 'urls' provided by DC may contain `\`, i.e. Windows path separators we need to replace into proper `/`, i.e. path separators for urls
    return `https://${
        config.backendBaseUrl
    }/assetproxy/${dcFilepathOrUrl.replaceAll('\\', '/')}?token=${accessToken}`;
};

export const prepareInfoBlockContent = (
    text: string | string[],
): { value: string }[] =>
    Array.isArray(text) ? text.map((value) => ({ value })) : [{ value: text }];

export const transformResidenceToString = (
    data: {
        value: {
            city?: string;
            street?: string;
            country?: string;
            zip?: string;
        };
        sources: TargetReportSourceType[];
    }[] = [],
): {
    value: string;
    sources: TargetReportSourceType[];
}[] =>
    data.map((item) => ({
        value: [
            item.value.street,
            item.value.city,
            item.value.zip,
            item.value.country,
        ]
            .filter(Boolean)
            .join(', '),
        sources: item.sources,
    }));

export const capitalize = (value: string): string =>
    value.charAt(0).toUpperCase() + value.slice(1);

const denormalizeName = (value: string): string => {
    let name = value ? value.toLocaleLowerCase() : '';
    name = replaceUmlauts(name);

    return name;
};

export const normalizeName = (value: string): string => {
    let name = value ? value.toLocaleLowerCase() : '';
    name = name.split(',').map(capitalize).join(', ');
    name = name.split(' ').map(capitalize).join(' ');

    return name;
};

export const transformProfileFieldsToInfoBlockItem = (
    profile: TargetProfileData | undefined,
    keys: (keyof Omit<TargetProfileData, 'name'> | 'firstname' | 'lastname')[],
): LegacyTargetCandidateInfoItem[] => {
    if (!profile) {
        return [];
    }

    const data: LegacyTargetCandidateInfoItem[] = [];
    keys.forEach((key) => {
        switch (key) {
            case 'firstname':
                data.push({
                    key,
                    values: [
                        ...new Set(
                            profile.name
                                ?.map((field) =>
                                    denormalizeName(field.value.first),
                                )
                                .filter(Boolean),
                        ),
                    ].map(normalizeName),
                    sources: [
                        ...new Set(...profile.name.map((name) => name.sources)),
                    ],
                });
                break;
            case 'lastname':
                data.push({
                    key,
                    values: [
                        ...new Set(
                            profile.name
                                ?.map((field) =>
                                    denormalizeName(field.value.last),
                                )
                                .filter(Boolean),
                        ),
                    ].map(normalizeName),
                    sources: [
                        ...new Set(...profile.name.map((name) => name.sources)),
                    ],
                });
                break;
            case 'birthday': {
                const birthdays = profile.birthday?.map((birthday) => {
                    const { value } = birthday;
                    let { start, end } =
                        typeof value === 'string'
                            ? { start: value, end: value }
                            : value;
                    if (
                        start === end &&
                        start.length === 4 &&
                        end.length === 4
                    ) {
                        start = [start, '01', '01'].join('-');
                        end = [end, '12', '31'].join('-');
                    }

                    return {
                        ...birthday,
                        value: { start, end },
                    };
                });
                const transformedBirthdayData =
                    transformBirthdayRangeToString(birthdays);
                data.push({
                    key,
                    values:
                        transformedBirthdayData?.map((field) =>
                            field.value.toString(),
                        ) || [],
                    sources: [
                        ...new Set(
                            ...(birthdays || []).map(
                                (birthday) => birthday.sources,
                            ),
                        ),
                    ],
                });
                break;
            }
            case 'age':
                data.push({
                    key,
                    values:
                        profile.age?.map((field) =>
                            i18n.t('yearsOld', { age: field.value }),
                        ) || [],
                    sources: [
                        ...new Set(
                            ...(profile.age || []).map(
                                (field) => field.sources,
                            ),
                        ),
                    ],
                });
                break;
            case 'residence': {
                const transformedResidenceData = transformResidenceToString(
                    profile.residence,
                );
                data.push({
                    key: 'placesLived',
                    values:
                        transformedResidenceData?.map((field) => field.value) ||
                        [],
                    sources: [
                        ...new Set(
                            ...(transformedResidenceData || []).map(
                                (field) => field.sources,
                            ),
                        ),
                    ],
                });
                break;
            }
            case 'wocoEntities':
            case 'educations':
                break;
            default:
                data.push({
                    key,
                    values: profile[key]?.map((field) => field?.value) || [],
                    sources: [
                        ...new Set(
                            ...(profile[key]?.map((field) => field?.sources) ||
                                []),
                        ),
                    ],
                });
        }
    });

    return data;
};

/**
 *
 * @param url: string - Social media public URL to be send to the CDN
 */
export const getCdnUrl = (url: string): string => {
    if (!url) {
        return url;
    }
    return url.slice(0, 4) === 'http' ? url : `${CDN_URL}/${url}`;
};

export const getSelectionStateBorderClass = (
    selectionState?: TargetPersonSelectionState,
    defaultClass = 'border-transparent',
): string =>
    !selectionState
        ? defaultClass
        : selectionState === TargetPersonSelectionState.Confirmed
        ? 'border-primary-4'
        : 'border-error-2';

export const withinSpanOfDays = (
    inputDate: string | number,
    daySpan = 30,
): boolean =>
    differenceInDays(new Date().getTime(), new Date(inputDate).getTime()) <=
    daySpan;

const companyStatusColors: Record<string, string[]> = {
    active: ['text-success-1'],
    'active (reorganization)': ['text-success-1'],
    ak: ['text-success-1'],
    insolvency: ['text-error-2'],
    bankruptcy: ['text-error-2'],
    'administratively suspended': ['text-error-2'],
    'in liquidation': ['text-warning-1'],
    'active (dormant)': ['text-lime-400'],
    'active (default of payment)': ['from-success-1', 'to-error-2'],
    'active (insolvency proceedings)': ['from-success-1', 'to-error-2'],
    'dissolved (bankruptcy)': ['from-neutral-400', 'to-error-2'],
    'dissolved (liquidation)': ['from-neutral-400', 'to-error-2'],
    dissolved: ['text-neutral-400'],
    'status unknown': ['text-neutral-400'],
    inactive: ['text-neutral-400'],
    ia: ['text-neutral-400'],
    'dissolved (merger or take-over)': ['text-neutral-400'],
    'inactive (no precision)': ['text-neutral-400'],
    default: ['text-neutral-400'],
};

export const getCompanyStatusClass = (status: string): string => {
    const colors = companyStatusColors[status.toLowerCase()];
    if (!colors) {
        return `text-neutral-400`;
    }
    const baseClasses =
        colors.length > 1
            ? ['bg-gradient-to-r bg-clip-text text-transparent']
            : [];
    return classNames(baseClasses, colors);
};

export const isCustomerExpired = (expiresAt?: Date | null): boolean =>
    !!expiresAt && isBefore(new Date(expiresAt), new Date());

export const nonProdDataTestId = (testId?: string): string | undefined => {
    // TODO: move the data-testid removal on production to webpack (there are babel plugins that do that)
    if (!testId || process.env.REACT_APP_STAGE === 'production') {
        return undefined;
    }

    return testId;
};

export function removeLegalSuffix(company: string): string {
    const pattern = new RegExp(
        `\\b(${COMPANY_SUFFIXES.map((suffix) =>
            suffix.toLowerCase().replace(/\./g, '\\.?'),
        ).join('|')})\\b`,
        'g',
    );

    return company
        .toLowerCase()
        .replace(pattern, '')
        .trim()
        .replace(/ {2,}|\./g, ' ');
}

export const generateAllCandidatesArray = (
    data: Omit<TargetCandidates, 'allCandidates'>,
    caseType: CaseType | null,
): TargetCandidates => {
    const allCandidates: Array<
        TargetCandidatesGroup | TargetGoogleCandidatesGroup
    > = [
        ...(data.candidates ?? [])
            .filter((candidate) => {
                return (
                    candidate.sources.length === 0 ||
                    allowedSourcesByCaseType(caseType).includes(
                        candidate.sources[0],
                    )
                );
            })
            .map((candidate) => {
                return {
                    ...candidate,
                    sources: candidate.sources.filter((source) => {
                        return allowedSourcesByCaseType(caseType).includes(
                            source,
                        );
                    }),
                };
            }),
        ...(data.googleCandidates ?? []).map((googleCandidateGroup) => ({
            ...googleCandidateGroup,
            isGoogleCandidate: true,
            score:
                googleCandidateGroup.score ?? googleCandidateGroup.groupScore, // for backwards compatibility
        })),
    ];

    return {
        ...data,
        allCandidates,
    };
};

export const mapStringToStringSet = (data: string): ReadonlySet<string> => {
    try {
        const array = Yup.array()
            .of(Yup.string().required())
            .required()
            .validateSync(JSON.parse(data));

        return new Set(array);
    } catch (error) {
        console.error(error);

        return new Set();
    }
};
