import {
    buildSearchUrl,
    EntityTypePlusAllText,
} from './queries/useSearchQuery';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import axios from 'axios';
import { AllDataArticle } from '../services/searchService';

type SearchFilter = {
    query: string;
    type: string;
    value?: any;
};

type SearchFilters = Record<string, SearchFilter>;

type ElasticSearchQueryProps = {
    caseId: string;
    targetId: string;
    page?: number;
    pageSize?: number;
    exactMatch?: number;
    query: string;
    filters?: SearchFilters;
    entityType?: EntityTypePlusAllText;
};

type ElasticSearchQueryOutput = {
    items: AllDataArticle[];
    count?: Record<string, any>;
    paging: {
        count: number;
        currentPage: number;
        nextPage: number;
        pagesCount: number;
    };
};

export const useElasticSearchQuery = (
    props: ElasticSearchQueryProps,
): UseInfiniteQueryResult<ElasticSearchQueryOutput> => {
    const {
        pageSize = 12,
        exactMatch,
        entityType,
        filters,
        caseId,
        targetId,
        query,
    } = props;

    return useInfiniteQuery<ElasticSearchQueryOutput>(
        [
            `search-${entityType}`,
            caseId,
            targetId,
            exactMatch,
            query,
            JSON.stringify(filters),
        ],
        ({ pageParam = 1 }) => {
            const url = buildSearchUrl({
                caseId,
                targetId,
                page: pageParam,
                type: entityType,
                pageSize,
                exactMatch,
                query,
            });

            return axios
                .post(url, filters ?? {})
                .then((response) => response.data);
        },
        {
            getNextPageParam: (lastPage) => {
                const { paging } = lastPage;
                return paging.nextPage ? paging.currentPage + 1 : undefined;
            },
            refetchOnWindowFocus: false,
            enabled: true,
            keepPreviousData: true,
        },
    );
};
