import axios, { AxiosPromise } from 'axios';
import config from '../backendConfig.json';
import {
    CompanyRole,
    ProcessTarget,
    TargetReportAttribute,
    TargetReportSourceType,
    WocoEntity,
} from '@indicium/common';
import { getAccessToken } from './authenticationService';
import {
    EntrepreneurialActivities,
    GoogleCandidateStatus,
    KeywordSynonymSuggestions,
    TargetCandidatesGroup,
    TargetGoogleCandidatesGroup,
    TargetPersonSelection,
} from '_types';
import { CompanyRoleWithPeriod } from '@indicium/common/src/types/Report/TargetCompany';
import { CaseType } from 'src/features/cases/CaseNew';

axios.interceptors.request.use(async (config) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
        config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
}, console.error);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('dataService error:', error);
        return Promise.reject(error);
    },
);

export type Paging = {
    count: number;
    pagesCount: number;
    currentPage: number;
    nextPage?: number;
    previousPage?: number;
    unknownPageCount?: boolean;
};

export enum TargetStatus {
    /**
     * The target has just been created. Workflow execution to retrieve potential
     * candidates is still pending
     */
    Created = 'Created',
    /** Workflow to retrieve potential candidates is running */
    FetchingCandidates = 'FetchingCandidates',
    /**
     * The search for potential candidates is completed. Waiting for user
     * selection which candidates match the desired target
     */
    CandidateSelectionPending = 'CandidateSelectionPending',
    /**
     * The user has completed the disambiguation step and finalized the selection
     * of candidates belonging to the desired target.
     */
    CandidateSelectionCompleted = 'CandidateSelectionCompleted',
    /**
     * The workflow to retrieve target details based on the selected candidate
     * list is running.
     */
    FetchingDetails = 'FetchingDetails',

    /**
     * The initial profile is available.
     */
    HasInitialProfile = 'HasInitialProfile',
    /**
     * The full pipeline has been completed and all result data is available.
     */
    Completed = 'Completed',
    /**
     * There was some error during report generation, so some or all parts of the
     * report might be missing. The available report will no longer change any more though.
     */
    CompletedWithIrregularities = 'CompletedWithIrregularities',
    /**
     * Fetching target data failed for some reason, e.g. the target was deleted or user is not assigned to it
     */
    Error = 'Error',
}

export type BaseEntry = {
    sources: TargetReportSourceType[];
};

export type SimpleEntry = BaseEntry & {
    value: string;
};

// TODO: Remove this types and switch to use TargetCompanyData from @indicium/common
export type CompanyData = {
    id: string;
    targetResultId: string;
    updatedAt: string;
    data: {
        address: {
            sources: TargetReportSourceType[];
            value: {
                city?: string;
                country?: string;
                street?: string;
                zip?: string;
            };
        }[];
        country: SimpleEntry[];
        email: SimpleEntry[];
        apiIds: {
            value: string;
            sources: TargetReportSourceType[];
        }[];
        managers: {
            value: {
                name: string;
                role: CompanyRole;
                nameElements: {
                    first: string;
                    last: string;
                    middle: string;
                    salutation: string;
                };
                orbisId: string;
            };
            sources: TargetReportSourceType[];
        }[];
        name: SimpleEntry;
        phone: SimpleEntry[];
        registrationDate: SimpleEntry[];
        registrationNumber: SimpleEntry[];
        role: {
            value: CompanyRoleWithPeriod;
            sources: TargetReportSourceType[];
        }[];
        shareholders: {
            value: {
                name: string;
                role: CompanyRole;
                orbisId: string;
            };
            sources: TargetReportSourceType[];
        }[];
        status: SimpleEntry[];
        subject: {
            value: {
                language: string;
                text: string;
            };
            sources: TargetReportSourceType[];
        }[];
        ubo?: {
            value: {
                name: string;
                role: CompanyRole;
                orbisId: TargetReportSourceType;
            };
            sources: TargetReportSourceType[];
        }[];
        website: SimpleEntry[];
        redFlags?: SimpleEntry[];
        redFlagWocoEntityIds?: SimpleEntry[];
        wocoEntities?: TargetReportAttribute<WocoEntity>[];
    };
};

export interface ProfileEducation {
    date_range?: {
        start: string;
        end: string;
    };
    display: string;
    degree?: string;
    school?: string;
}

export interface ProfileDataEducation {
    value: ProfileEducation;
    sources: TargetReportSourceType[];
}

export type ProfileData = {
    age?: SimpleEntry[];
    apiIds?: SimpleEntry[];
    birthday?: Array<{
        value: {
            start: string;
            end: string;
        };
        sources: TargetReportSourceType[];
    }>;
    emails?: SimpleEntry[];
    name: Array<{
        value: {
            last: string;
            first: string;
            middle?: string;
        };
        sources: TargetReportSourceType[];
    }>;
    nationality?: SimpleEntry[];
    birthPlace?: SimpleEntry[];
    phones?: SimpleEntry[];
    residence?: Array<{
        value: {
            city?: string;
            street?: string;
            country?: string;
            zip?: string;
        };
        sources: TargetReportSourceType[];
    }>;
    websites?: SimpleEntry[];
    usernames?: {
        value: {
            name: string;
            site?: string;
            profilePicturePath?: string;
            profileUrl?: string;
        };
        sources: TargetReportSourceType[];
    }[];
    redFlags?: SimpleEntry[];
    redFlagWocoEntityIds?: SimpleEntry[];
    companies?: CompanyData[];
    emailBreaches?: {
        value: {
            sourceEmail: string;
            name: string;
            breachDate: string;
            description: string;
        };
        sources: TargetReportSourceType[];
    }[];
    occupation?: SimpleEntry[];
    wocoEntities?: TargetReportAttribute<WocoEntity>[];
    educations?: ProfileDataEducation[];
};

export type WorkflowType =
    | 'candidates'
    | 'network'
    | 'press'
    | 'profile'
    | 'socialmedia';

// TODO: Move to @indicium/common
export type CVInfoEntry = {
    companyName?: string;
    role?: string;
    from?: string;
    to?: string;
    isCurrentPosition: boolean;
};

export type Resume = {
    companyhouse: CVInfoEntry[];
    orbis: CVInfoEntry[];
    pipl: CVInfoEntry[];
};

export type ReportStep =
    | 'profile'
    | 'google_profile'
    | 'press'
    | 'network'
    | 'socialmedia'
    | 'entire_workflow';

export interface StepStatus {
    step: ReportStep;
    status: TargetStatus;
}

export type Target = ProcessTarget & {
    id: string;
    status: TargetStatus;
    createdAt: string;
    updatedAt: string;
    profile?: {
        createdAt: string;
        data: ProfileData;
    } | null;
    profileImages?: { url: string; createdAt: string }[];
    companies?: CompanyData[] | null;
    entrepreneurialActivities: EntrepreneurialActivities;
    workflowErrors?: {
        id: string;
        error: string;
        type: WorkflowType;
        cause: {
            trace: string[];
            errorType: string;
            errorMessage: string;
        };
        targetId: string;
        createdAt: string;
        updatedAt: string;
    }[];
    creator: Creator | null;
    stepStatuses: StepStatus[];
    fullname: string;
    caseId: string;
    case: {
        id: string;
        title: string;
    };
    errorEntityNames: string[];
};

export type Creator = {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
};

export type AnalysisType = 'unknown' | 'naturalPerson' | 'unnaturalPerson';

export type CaseData = {
    createdAt: string;
    creator: Creator;
    creatorId: string;
    customerId: string;
    // 'id' is undefined in the UI when a new Case is created, but is generated automatically on save
    id?: string;
    title: string;
    isActive: boolean;
    updatedAt: string;
    // 'userIds' attaches User as CaseAgents to this Case
    userIds?: string[];
    analysisType: AnalysisType;
    option?: {
        option: CaseType | null;
        backgroundCheck?: boolean;
        publicInfo?: boolean;
        privateSocialMedia?: boolean;
        customSearchTerms?: boolean;
    } | null;
    // this not actually part of the CaseData, we use it to attach case-specific keywords fetched using the separate useCaseKeywordsQuery;
    keywords?: CaseKeyword[];
};

export type CaseAgentData = {
    id?: string;
    caseId: string;
    userId: string;
};

type CaseKeywordTranslation = {
    id: string;
    caseKeywordId: string;
    value: string;
    language: string;
};

export type CaseKeyword = {
    id: string;
    value: string;
    type: string;
    caseId: CaseData['id'];
    // case: CaseEntity; // we want to ignore this
    translations: CaseKeywordTranslation[];
};

export const updateTargetCandidateSelection: (
    caseId: string,
    targetId: string,
    data: {
        candidates: TargetPersonSelection[];
        googleCandidates: GoogleCandidateStatus[];
    },
    // Whether the user has triggered the analysis or whether they have only updated
    finalized: boolean,
) => AxiosPromise<void> = (caseId, targetId, data, finalized) =>
    axios.patch(
        `https://${config.backendBaseUrl}/cases/${caseId}/targets/${targetId}`,
        {
            data,
            status: finalized
                ? 'CandidateSelectionCompleted'
                : 'CandidateSelectionPending',
        },
    );

//TODO: migrate all Press types to the common repo. ticket: https://produktmacher.atlassian.net/browse/IND-902
export type BasePressSubject = {
    id: string;
    networkLevel: number;
    data: {
        data: {
            name: string;
            role: string;
        };
    };
};
export type PressSubjectPerson = BasePressSubject & {
    data: {
        data: {
            nameElements?: {
                salutation?: string;
                first: string;
                last: string;
                middle?: string;
            };
        };
        type: 'person';
    };
};

export type PressSubjectCompany = BasePressSubject & {
    data: {
        type: 'company';
    };
};

/**
 * Data on social media posts mostly as documented in the DC API documentation.
 *
 * WARNING: The DC API documentation isn't accurate. This type will need more work in coordination
 * with DC.
 */
export type SocialMediaPost = {
    id: string;
    createdAt: string;
    updatedAt: string;
    /**
     * This contains only the date as YYYY-MM-DD
     */
    publishedAt: string;
    source:
        | 'Facebook'
        | 'Twitter'
        | 'Instagram'
        | 'VKontakte'
        | 'LinkedIn'
        | 'Xing';
    data: {
        // Undocumented but present:
        elementScreenshot?: string;
        mediaUrl?: string;

        itemType?:
            | 'Tweet'
            | 'Retweet'
            | 'Facebook Post'
            | 'Instagram Post'
            | 'Facebook Comment'
            | 'Instagram Comment'
            | string;
        /**
         * The time in which the item was seen and copied.
         * ISO8601 timestamp in `Date(...)` string wrapper
         * example: Date(2018-12-20T07:00:00Z)
         */
        dateCollected?: string;
        /**
         * The original posting time as stated by the source.
         * ISO8601 timestamp in `Date(...)` string wrapper
         * example: Date(2018-12-20T07:00:00Z)
         */
        datePublished?: string;
        /** example: Twitter Web Client */
        postingPlatform?: string;
        /** example: Android */
        postingDevice?: string;
        /**
         * A URL, a Permanent link to the item’s page in the source website
         * example: https://www.facebook.com/POTUS/posts/252953253447877
         */
        url?: string;
        /**
         * Numeric or textual identifier used by the source to represent the collected item.
         * example: "252953253447877"
         */
        contentId?: string;
        /**
         * Numeric or textual id used by the source to represent the direct parent of the collected items,
         * which is not necessarily the first post in the conversation.
         */
        parentItemId?: string;
        /** Two-letter or three-letter code of the collected item’s language, declared by the source */
        inLanguage?: string;
        /** The title as it appears in the source */
        headline?: string;
        /**
         * The item’s main body of text which was authored by the account, be it a Twitter Status, a
         * YouTube Video description or WhatsApp Message text.
         */
        body?: string;
        /**
         * List of words or phrases which accompany the post (tags) or included in the post body
         * (hashtags) to signal message topics.
         */
        tags?: string[];
        /** Post Like Count */
        likeCount?: number;
        /** Post Dislike Count */
        dislikeCount?: number;
        /** Post View Count */
        viewCount?: number;
        /** Post Comment Count */
        commentCount?: number;
        /** Post Share Count */
        shareCount?: number;
        /** Post Favorite Count */
        favoriteCount?: number;
        /** Post Retweet Count */
        retweetCount?: number;
        /**
         * The unique, mostly  permanent and numeric, identifier used by the source to represent the post
         * author
         * example: "101078858635318"
         */
        profileId?: string;
        /**
         * The username, often limited to alphanumeric text, used by the source to represent the post
         * author. In most cases it is selected by the account owner with limited update options.
         * example: POTUS
         */
        username?: string;
        /**
         * The author’s name, also known as display name, can represent a person, a company or an event.
         * example: President Joe Biden
         */
        profileName?: string;
        /**
         * The post author’s full name filled only when the account owner is a person
         * example: Joseph Robinette Biden Jr.
         */
        fullName?: string;
        /**
         * The relative file path of the downloaded author's profile image.
         * example: /fs/dv/scontent.fsdv2-1.fna.fbcdn.net/v/t1.6435-9/171431598_178401114236425_16864650705843130_n.jpg
         */
        profileImageUrl?: string;
        /**
         * The original permanent online link of the author's profile image.
         * example: https://scontent.fsdv2-1.fna.fbcdn.net/v/t1.6435-9/171431598_178401114236425_16864650705843130_n.jpg?_nc_cat=1&ccb=1-3&_nc_sid=09cbfe&_nc_ohc=rKmXUjoO7Z4AX_ZtgAt&_nc_ht=scontent.fsdv2-1.fna&oh=d7ea6488dedfafaf3e3d8552d56d23e7&oe=60DC42F8
         */
        profileImageWebAddress?: string;
        /** example: https://www.facebook.com/POTUS/ */
        profileUrl?: string;
    };
};

export type MixedTargetCandidateGroup =
    | TargetCandidatesGroup
    | TargetGoogleCandidatesGroup;

export type Fingerprint = number[];

export interface UserInputFingerprints {
    positive: Fingerprint | null;
    negative: Fingerprint | null;
}

export interface CandidateFingerprint {
    [candidateId: string]: Fingerprint | null;
}

export interface TargetCandidates extends TargetSelectedCandidates {
    status: TargetStatus;
    candidateFingerprints: CandidateFingerprint[];
    userInputFingerprints: UserInputFingerprints;
    preSelectedCandidates?: {
        included: { groupId: string; [key: string]: any }[];
        excluded: { groupId: string; [key: string]: any }[];
    };
    userInput: ProcessTarget;
}

export type AllCandidates = MixedTargetCandidateGroup & {
    totalScore?: number;
    fingerprintScore?: number;
};

export interface TargetSelectedCandidates {
    candidates: TargetCandidatesGroup[] | null;
    googleCandidates: TargetGoogleCandidatesGroup[] | null;
    allCandidates: AllCandidates[];
}

// Keywords translation suggestions fetch
export const fetchTranslationSuggestions: (
    keywords: Array<string>,
) => AxiosPromise<void> = (keywords) =>
    axios.post(
        `https://${config.backendBaseUrl}/analysis/suggest-translations`,
        keywords,
    );

export const fetchSynonymsSuggestions = async (
    keywordTranslations: Array<string>,
): Promise<KeywordSynonymSuggestions> =>
    (
        await axios.post(`https://${config.backendBaseUrl}/analysis/synonyms`, {
            keywords: keywordTranslations,
        })
    ).data;

export enum SocialMediaErrorType {
    SDWarning = 'SDWarning',
    SDError = 'SDError',
    HttpError = 'HttpError',
    ValidationError = 'ValidationError',
}

export enum SocialMediaErrorPath {
    FacebookUser = 'facebook.user',
    FacebookPosts = 'facebook.posts',
    FacebookFriends = 'facebook.friends',
    FacebookFollowing = 'facebook.following',
    FacebookFollowers = 'facebook.followers',
    FacebookPost = 'facebook.post',
    FacebookVideo = 'facebook.video',
    FacebookPhoto = 'facebook.photo',
    TwitterUser = 'twitter.user',
    TwitterTweets = 'twitter.tweets',
    TwitterFollowing = 'twitter.following',
    TwitterFollowers = 'twitter.followers',
    TwitterTweet = 'twitter.tweet',
    LinkedInUser = 'linkedin.user',
    LinkedInPosts = 'linkedin.posts',
    LinkedInPost = 'linkedin.post',
    LinkedInEducations = 'linkedin.educations',
    LinkedInPositions = 'linkedin.positions',
    InstagramUser = 'instagram.user',
    InstagramFollowing = 'instagram.following',
    InstagramFollowers = 'instagram.followers',
    InstagramPosts = 'instagram.posts',
    InstagramPost = 'instagram.post',
    InstagramReel = 'instagram.reel',
    InstagramStory = 'instagram.story',
    XingUser = 'xing.user',
    XingContacts = 'xing.contacts',
}

export type SocialMediaError = {
    url: string;
    type: SocialMediaErrorType;
    errorPath: SocialMediaErrorPath;
};
