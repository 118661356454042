import { FC, SyntheticEvent, useEffect, useState } from 'react';
import classnames from 'classnames';
import classNames from 'classnames';

type colorOptions = 'blue' | 'red';

const fontColor: Record<colorOptions, string> = {
    blue: 'text-primary-4',
    red: 'text-error-2',
};
const hoverFontColor: Record<colorOptions, string> = {
    blue: 'hover:text-primary-3',
    red: 'hover:text-error-1',
};
const border: Record<colorOptions, string> = {
    blue: 'border-primary-4',
    red: 'border-error-2',
};
const focusWithinBorder: Record<colorOptions, string> = {
    blue: 'focus-within:border-primary-4',
    red: 'focus-within:border-error-2',
};

export type CheckboxProps = {
    id?: string;
    label?: string;
    initialIsSelected?: boolean;
    labelPosition?: 'append' | 'prepend' | 'before' | 'after';
    labelColor?: colorOptions;
    labelClassName?: string;
    centered?: boolean;
    onChange: (isSelected: boolean) => void;
    disabled?: boolean;
    className?: string;
    color?: colorOptions;
    size?: number;
    additionalHtmlAttributes?: Record<string, string | undefined>;
};

const getClassesFromLabelPosition = (
    labelPosition: CheckboxProps['labelPosition'],
): string | null => {
    switch (labelPosition) {
        case 'prepend':
            return 'flex-row-reverse items-center';
        case 'before':
            return 'flex-col-reverse';
        case 'after':
            return 'flex-col';
        default:
            return 'flex-row items-center';
    }
};

export const Checkbox: FC<CheckboxProps> = ({
    id,
    label,
    initialIsSelected = false,
    labelPosition = 'before',
    labelColor,
    labelClassName,
    centered = true,
    onChange,
    disabled = false,
    className,
    color = 'blue',
    size = 6,
    additionalHtmlAttributes,
}: CheckboxProps) => {
    const [isSelected, setIsSelected] = useState(initialIsSelected);
    useEffect(() => {
        setIsSelected(initialIsSelected);
    }, [initialIsSelected]);

    return (
        <label
            className={classNames(
                getClassesFromLabelPosition(labelPosition),
                centered ? 'justify-center items-center' : null,
                'flex gap-1 text-sm font-bold',
                !className?.includes('text-') && 'text-neutral-500',
                className,
            )}
            // We want the click event to be kept within the component since we're not binding onClick from outside
            onClick={(e: SyntheticEvent<HTMLLabelElement>) =>
                e.stopPropagation()
            }
            {...additionalHtmlAttributes}
        >
            <div
                className={classnames(
                    'relative border rounded transition-all',
                    disabled
                        ? 'bg-neutral-400'
                        : `cursor-pointer bg-neutral-50 hover:${border[color]} hover:${focusWithinBorder[color]} transition-colors`,
                    `w-${size} h-${size} flex flex-shrink-0 justify-center items-center`,
                    isSelected
                        ? `${border[color]} ${focusWithinBorder[color]}`
                        : 'border-neutral-400 focus-within:border-neutral-400',
                )}
            >
                <input
                    id={id}
                    type="checkbox"
                    className="absolute cursor-pointer opacity-0 disabled:pointer-events-none disabled:cursor-default"
                    value={isSelected ? 'on' : 'off'}
                    checked={isSelected}
                    onChange={() => {
                        onChange(!isSelected);
                        setIsSelected(!isSelected);
                    }}
                    disabled={disabled}
                />
                <svg
                    className={`fill-current hidden p-1 w-full h-full ${fontColor[color]} pointer-events-none rounded`}
                    viewBox="0 0 20 20"
                >
                    <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
            </div>
            {label && (
                <div
                    className={classnames(
                        'select-none transition-colors',
                        labelColor && fontColor[labelColor],
                        labelColor && !disabled && hoverFontColor[labelColor],
                        disabled ? 'cursor-default' : 'cursor-pointer',
                        labelClassName,
                    )}
                >
                    {label}
                </div>
            )}
        </label>
    );
};
