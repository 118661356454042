import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PressCard } from '_organisms';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import {
    Alert,
    InfiniteScrollV2,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { SearchFilterValue } from '_types';
import { PressArticle } from 'src/services/searchService';

import pressFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';

type PressFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

export const Press: FC<ResultsProps> = ({
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<PressFilters>({
            filters: pressFilters,
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'press',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const buildIndicators = (itemId: string) =>
        entityIndicators
            .filter((s) => s.entityId === itemId)
            .flatMap(({ indicators }) => indicators);

    return (
        <DefaultPageLayout title={t('rawData.press')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <div className="flex-1">
                <WorkflowError
                    errors={targetData?.workflowErrors}
                    path="press"
                />

                {targetData?.status === 'HasInitialProfile' && (
                    <Alert
                        alertType="warning"
                        headline={t('profileDataNotFinal')}
                        className="mb-2"
                    />
                )}

                <div
                    className="relative mt-6 z-10 flex flex-col gap-4"
                    data-testid={nonProdDataTestId('press')}
                >
                    {isLoading ? (
                        <div className="absolute top-0 flex justify-center w-full z-0">
                            <LoadingSpinner message={t('profileLoading')} />
                        </div>
                    ) : isError ? (
                        <Error
                            headline={t('profileErrorHeadline')}
                            message={t('profileErrorRetryMessage')}
                        />
                    ) : null}
                    {articles.length ? (
                        <>
                            {articles.map((item) => (
                                <PressCard
                                    key={item.id}
                                    data={item as PressArticle}
                                    hideArticleType={true}
                                    query={searchInputProps.value}
                                    caseData={caseData}
                                    targetData={targetData}
                                    indicators={buildIndicators(item.id)}
                                />
                            ))}
                            {articleMeta?.paging && (
                                <InfiniteScrollV2
                                    paging={articleMeta.paging}
                                    isLoading={isFetchingNextPage}
                                    setPageNumber={handlePageChange}
                                />
                            )}
                        </>
                    ) : isLoading ? null : (
                        <Alert alertType="info" message={t('noData')} />
                    )}
                </div>
            </div>
        </DefaultPageLayout>
    );
};
