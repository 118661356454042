import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type { RegisterDataArticle } from '../../../services/searchService';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import {
    Alert,
    InfiniteScrollV2,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { CompanyCard } from '_organisms';
import { SearchFilterValue } from '_types';

import { companyFilters } from './filters';
import { nonProdDataTestId } from '_utils';
import { RoleType } from './RoleType';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';

type CompanyFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

export type RegisterdatenProps = ResultsProps & { accessToken: string };

export const Registerdaten: FC<RegisterdatenProps> = ({
    targetData,
    caseData,
}) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const relevanceFilter = useMemo(
        () => ({
            type: 'Relevance',
            title: t('commercialRegister.filterTitle'),
            field: 'companyTargetRoles.roleType.keyword',
            options: [
                {
                    label: t('commercialRegister.relevantCheckbox'),
                    value: [RoleType.Manager, RoleType.Shareholder],
                    tooltipContent: t('commercialRegister.relevantTooltip'),
                },
            ],
        }),
        [t],
    );

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<CompanyFilters>({
            filters: [...companyFilters, relevanceFilter],
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'companies',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    return (
        <DefaultPageLayout title={t('rawData.registerData')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <WorkflowError errors={targetData?.workflowErrors} path="profile" />
            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}
            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('registerdaten')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <Fragment>
                        {articles.map((item) => (
                            <CompanyCard
                                key={item.id}
                                data={item as RegisterDataArticle}
                                hideArticleType={true}
                                query={searchInputProps.value}
                                targetData={targetData}
                                caseData={caseData}
                                initiallyExpanded={true}
                            />
                        ))}
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
