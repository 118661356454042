import { NoTimeExperience } from '../../types';
import React, { FC } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const Experience: FC<{ experience: NoTimeExperience }> = ({ experience }) => {
    return (
        <div className={classnames('flex flex-row py-1')}>
            <div className="flex flex-col justify-start w-full">
                <span className="text-primary-1 font-bold">
                    {experience.header}
                </span>
                {experience.subHeader.length > 0 && (
                    <ul className="list-disc pl-6">
                        {experience.subHeader.map((subHeader, i) => (
                            <li className="text-primary-1" key={subHeader + i}>
                                {subHeader}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

const Header: FC<{ title: string }> = ({ title }) => (
    <div className="text-neutral-400 font-semibold pb-2 uppercase">{title}</div>
);

export const NoTimeframeExperience: FC<{ data: NoTimeExperience[] }> = ({
    data,
}) => {
    const { t } = useTranslation();
    const education = data.filter(
        (experience) => experience.type === 'education',
    );
    const work = data.filter((experience) => experience.type === 'work');

    return (
        <div className="flex gap-3 justify-between rounded-lg bg-neutral-50 p-6">
            {work.length > 0 && (
                <div className="flex flex-col gap-3">
                    <Header title={t('cVAnalysis.work')} />
                    {work.map((experience, i) => (
                        <Experience
                            experience={experience}
                            key={i.toString()}
                        />
                    ))}
                </div>
            )}
            {education.length > 0 && (
                <div className="flex flex-col gap-3">
                    <Header title={t('cVAnalysis.education')} />
                    {education.map((experience, i) => (
                        <Experience
                            experience={experience}
                            key={i.toString()}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
