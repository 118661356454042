import { useCallback, useMemo } from 'react';
import FiltersFactory from '../factories/filters.factory';
import { SearchActionTypes, SearchState, useSearch } from './useSearch';
import { isNeitherNullNorUndefined } from '_utils';
import { SearchType } from '../types/SearchFilterValue';
import { AutoCompleteResult } from '_molecules';

type RawDataFilterProps = {
    filters: Array<any>;
};

type RawDataFilterOutput<T extends unknown> = {
    renderedFilters: JSX.Element[];
    value: string;
    onSearchInput: (query: string, exactMatch?: SearchType) => void;
    onFilterChange: (filters: any) => void;
    autocomplete: (query: string) => Promise<AutoCompleteResult[]>;
    filtersState: SearchState<T>;
};

export const useRawDataFilters = <T extends unknown>(
    props: RawDataFilterProps,
): RawDataFilterOutput<T> => {
    const { filters } = props;

    const filtersFactory = useMemo(() => new FiltersFactory(), []);
    const [searchState, dispatch] = useSearch<T>();

    const renderedFilters = filters
        .map((filter) => filtersFactory.create(filter, searchState, dispatch))
        .filter(isNeitherNullNorUndefined);

    const onSearchInput = useCallback(
        (query: string, exactMatch?: SearchType) => {
            dispatch({
                type: SearchActionTypes.SetQuery,
                query,
                exactMatch: exactMatch === 'exact' ? 1 : 0,
            });
        },
        [dispatch],
    );

    const onFilterChange = useCallback(
        (_filters) => {
            dispatch({ type: SearchActionTypes.SetFilter, filters: _filters });
        },
        [dispatch],
    );

    const autocomplete = useCallback(async (): Promise<
        AutoCompleteResult[]
    > => {
        return Promise.resolve([]);
    }, []);

    return {
        renderedFilters,
        value: searchState.query ?? '',
        onSearchInput,
        autocomplete,
        onFilterChange,
        filtersState: searchState,
    };
};
