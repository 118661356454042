/* eslint-env commonjs */
/* eslint-disable @typescript-eslint/no-var-requires */
/* istanbul ignore file */
module.exports = {
    content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
    safelist: ['w-7', 'h-7'], // we compute the class dynamically the one place we use it, so tailwind doesn't detect its usage
    theme: {
        screens: {
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1440px',
            '3xl': '1920px',
            '4xl': '2560px',
        },
        spacing: {
            0: '0px', //           0px
            0.25: '0.0625rem', //  1px
            0.5: '0.125rem', //    2px
            1: '0.25rem', //       4px
            1.5: '0.375rem', //    6px
            2: '0.5rem', //        8px
            2.5: '0.625rem', //    10px
            3: '0.75rem', //       12px
            3.5: '0.875rem', //    14px
            4: '1rem', //          16px
            4.5: '1.125rem', //    18px
            5: '1.25rem', //       20px
            6: '1.5rem', //        24px
            7: '1.75rem', //       28px
            7.5: '1.875rem', //    30px
            8: '2rem', //          32px
            9: '2.25rem', //       36px
            10: '2.5rem', //       40px
            11: '2.75rem', //      44px
            12: '3rem', //         48px
            12.5: '3.125rem', //   50px
            13: '3.25rem', //      52px
            14: '3.5rem', //       56px
            16: '4rem', //         64px
            18: '4.5rem', //       72px
            20: '5rem', //         80px
            24: '6rem', //         96px
            25: '6.25rem', //      100px
            28: '7rem', //         112px
            30: '7.5rem', //       120px
            32: '8rem', //         128px
            36: '9rem', //         144px
            40: '10rem', //        160px
            44: '11rem', //        176px
            48: '12rem', //        192px
            50: '12.5rem', //      200px
            52: '13rem', //        208px
            56: '14rem', //        224px
            60: '15rem', //        240px
            64: '16rem', //        256px
            72: '18rem', //        288px
            75: '18.75rem', //     300px
            80: '20rem', //        320px
            96: '24rem', //        384px
            100: '25rem', //       400px
            120: '30rem', //       480px
            150: '37.5rem', //     600px
            200: '50rem', //       800px
            112.5: '28.125rem', // 450px
            'fit-content': 'fit-content',
            'max-content': '83.75rem', // 1340px -> this plus the sidebar make 1440px
            'half-max-content': '50rem', // 800px -> a bit more than half of max-content
            'flow-text-lg': '41.5rem', // 664px -> about 75 chars wide with text-lg (16px OpenSans)
            'flow-text-base': '30rem', // 480px -> about 75 chars wide with text-base (14px OpenSans)
            'result-page': 'calc(100vw - 12.5rem - 6.25em)', // 100vw - both sidebars
            'result-page-collapsed-sidebar': 'calc(100vw - 6.25em)', // 100vw - just the main sidebar
            'content-height': 'calc(100vh - 5rem)', // 100vh - header
            'inside-content-height': 'calc(100vh - 5rem - 2.25rem)', // 100vh - header - default page layout bottom padding
        },
        extend: {
            borderWidth: {
                6: '6px',
            },
            boxShadow: {
                card: '0 2px 16px rgba(23, 53, 72, 0.25)',
            },
            dropShadow: {
                card: '0 2px 16px rgba(23, 53, 72, 0.25)',
            },
            colors: {
                transparent: 'transparent',
                current: 'currentColor',
                inherit: 'inherit',

                white: '#FFFFFF',
                black: '#111111',

                blue: {
                    100: '#DEF5FF',
                    400: '#3CA4D4',
                    500: '#1E87B7',
                    600: '#006796',
                    800: '#01364F',
                    900: '#012232',
                },

                red: {
                    100: '#FEF2F2',
                    200: '#FECACA',
                    400: '#F87171',
                    500: '#EF4444',
                    700: '#B91C1C',
                    800: '#991B1B',
                },

                yellow: {
                    100: '#FFFBEB',
                    200: '#FDE68A',
                    300: '#FCD34D',
                    400: '#FBBF24',
                    700: '#B45309',
                    800: '#92400E',
                },

                green: {
                    100: '#D1FAE5',
                    400: '#6EE7B7',
                    600: '#059669',
                    700: '#047857',
                    800: '#065F46',
                },

                gray: {
                    50: '#F9FAFB',
                    100: '#F3F4F6',
                    200: '#E5E7EB',
                    300: '#D1D5DB',
                    400: '#9CA3AF',
                    500: '#6B7280',
                    600: '#4B5563',
                    700: '#374151',
                    800: '#1F2937',
                    900: '#111827',
                },

                font: {
                    light: '#F9F9F9',
                    dark: '#173548', // this is the same as primary-1 fyi
                },

                primary: {
                    1: '#173548',
                    2: '#2A5169',
                    3: '#176595',
                    4: '#2AA3DC',
                    5: '#3F6E8C',
                },

                contrast: {
                    1: '#55B497',
                    2: '#2CA4AB',
                    3: '#8ED2C6',
                    4: '#2CA9C5',
                    5: '#2A5169',
                },

                neutral: {
                    50: '#FEFEFE',
                    100: '#F9F9F9',
                    200: '#F1F1F1',
                    250: '#EFEFEF',
                    300: '#DDE3E6',
                    400: '#D5D5D5',
                    450: '#a1a1a1',
                    500: '#818181',
                    600: '#757575',
                },

                error: {
                    1: '#ED5151',
                    2: '#F36966',
                    3: '#F98787',
                },

                warning: {
                    1: '#E0C565',
                    2: '#D7A001',
                },

                success: {
                    1: '#7CBD7B',
                },

                highlight: {
                    caseKeywords: {
                        1: '#2AA3DC',
                        2: '#49C1FA',
                        3: '#91BED3',
                    },
                    targetCompanies: {
                        1: '#2CA4AB',
                        2: '#34C2CA',
                        3: '#88CED2',
                    },
                    targetPerson: {
                        1: '#8ED2C6',
                        2: '#63E0C9',
                        3: '#9BF4E5',
                    },
                    suspicionKeywords: {
                        1: '#2B8BC5',
                        2: '#76B9E1',
                        3: '#BBE6FF',
                    },
                    searchQuery: {
                        1: '#FCD34D',
                        2: '#FDE68A',
                        3: '#FBBF24',
                    },
                },
            },
            height: {
                'fit-content': 'fit-content',
                'fill-available': 'webkit-fill-available',
            },
            keyframes: {
                zoomInOut: {
                    '0%, 6%, 12%, 100%': {
                        transform: 'scale(1)',
                    },
                    '3%, 9%': { transform: 'scale(1.1)' },
                },
                spinAndStay: {
                    '0%': { transform: 'rotate(0deg)' },
                    '5%, 50%': { transform: 'rotate(180deg)' },
                    '55%, 100%': { transform: 'rotate(360deg)' },
                },
                wiggle: {
                    '0%, 4%, 8%, 12%, 16%, 100%': { transform: 'rotate(0deg)' },
                    '2%, 14%': { transform: 'rotate(-7deg)' },
                    '6%, 10%': { transform: 'rotate(7deg)' },
                },
                drop: {
                    '0%': { transform: 'translateY(-100%)', opacity: '0' },
                    '50%': { transform: 'translateY(10%)', opacity: '1' },
                    '100%': { transform: 'translateY(0)' },
                },
            },
            animation: {
                'spin-slow': 'spin 3s linear infinite',
                'zoom-in-out': 'zoomInOut 10s linear 1',
                wiggle: 'wiggle 10s linear 1',
                'spin-and-stay': 'spinAndStay 18s linear infinite',
                drop: 'drop 1s ease-out 1',
            },
            lineClamp: {
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
                10: '10',
                11: '11',
                12: '12',
                13: '13',
                14: '14',
                15: '15',
                16: '16',
                17: '17',
                18: '18',
                19: '19',
                20: '20',
            },
            fontFamily: {
                sans: [
                    'OpenSansVariable',
                    'OpenSans',
                    'Helvetica',
                    'sans-serif',
                ],
                jost: ['JostVariable', 'Jost', 'sans-serif'],
            },
            fontSize: {
                h1: ['2.625rem', { lineHeight: '3rem' }],
                h2: ['2.25rem', { lineHeight: '2.625rem' }],
                h3: ['1.75rem', { lineHeight: '2.25rem' }],
                h4: ['1.375rem', { lineHeight: '2rem' }],
                h5: ['1rem', { lineHeight: '1.25rem' }],
                label: ['0.875rem', { lineHeight: '1.25rem' }],
                'form-label': ['0.875rem', { lineHeight: '1rem' }],
                tooltip: ['0.875rem', { lineHeight: '1rem' }],
                lg: ['1rem', { lineHeight: '1.5rem' }],
                base: ['0.875rem', { lineHeight: '1.25rem' }],
                sm: ['0.75rem', { lineHeight: '1rem' }],
                xs: ['0.5rem', { lineHeight: '0.5rem' }],
                icon: ['1.75rem', { lineHeight: '0' }],
            },
            scale: {
                300: '3',
            },
            zIndex: {
                bg: '-1',
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5',
                6: '6',
                7: '7',
                8: '8',
                9: '9',
            },
        },
    },
    corePlugins: {
        container: false,
    },
    plugins: [
        require('@tailwindcss/aspect-ratio'),
        require('@tailwindcss/forms'),
        require('tailwind-bootstrap-grid')({}),
        require('tailwind-scrollbar'),
    ],
};
