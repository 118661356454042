import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ResultsProps } from '../Results';
import {
    Alert,
    InfiniteScrollV2,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { Error, LoadingSpinner } from '_atoms';
import {
    CompanyCard,
    PictureCard,
    PressCard,
    ResultCounters,
    SearchResultCard,
    SocialMediaCard,
    WordStratus,
} from '_organisms';

import allDataFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { SearchFilterValue } from '_types';

const mockKeywords = [
    { value: 'Millionen Dollar', count: 4 },
    { value: 'Geldauflage', count: 23 },
    { value: 'Dating-Riese', count: 5 },
    { value: 'Dresden', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'tubfui', count: 5 },
    { value: 'itubfui', count: 5 },
    { value: 'hitubfui', count: 5 },
    { value: 'ghitubfui', count: 5 },
    { value: 'bfui', count: 5 },
];

type AllDataFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

export const AllDataSearch: FC<ResultsProps> = ({
    accessToken,
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<AllDataFilters>({
            filters: allDataFilters,
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'allData',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const buildIndicators = (itemId: string) =>
        entityIndicators
            .filter((s) => s.entityId === itemId)
            .flatMap(({ indicators }) => indicators);

    return (
        <DefaultPageLayout title={t('rawData.search')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <ResultCounters count={articleMeta?.count} />
            <WordStratus
                keywords={mockKeywords}
                onKeywordClick={(i) => {
                    searchInputProps.onSearchInput(
                        `${searchInputProps.value} ${mockKeywords[i].value}`,
                    );
                }}
                className="hidden"
            />

            <WorkflowError errors={targetData?.workflowErrors} path="profile" />

            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}
            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('all-data-search')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <Fragment>
                        {articles.map((item) => {
                            switch (item.type) {
                                case 'press':
                                    return (
                                        <PressCard
                                            key={item.id}
                                            data={item}
                                            query={searchInputProps.value}
                                            targetData={targetData}
                                            caseData={caseData}
                                            indicators={buildIndicators(
                                                item.id,
                                            )}
                                        />
                                    );
                                case 'SocialAccountConnection':
                                    return (
                                        <SocialMediaCard
                                            key={item.id}
                                            data={item}
                                            accessToken={accessToken}
                                            targetData={targetData}
                                            caseData={caseData}
                                            indicators={buildIndicators(
                                                item.id,
                                            )}
                                        />
                                    );
                                case 'SocialAccountPost':
                                    return (
                                        <SocialMediaCard
                                            key={item.id}
                                            data={item}
                                            query={searchInputProps.value}
                                            accessToken={accessToken}
                                            targetData={targetData}
                                            caseData={caseData}
                                            indicators={buildIndicators(
                                                item.id,
                                            )}
                                        />
                                    );
                                case 'Image':
                                    return (
                                        <PictureCard
                                            key={item.id}
                                            data={item}
                                            query={searchInputProps.value}
                                            targetData={targetData}
                                            caseData={caseData}
                                            indicators={buildIndicators(
                                                item.id,
                                            )}
                                        />
                                    );
                                case 'WebPage':
                                    return (
                                        <SearchResultCard
                                            key={item.id}
                                            data={item}
                                            query={searchInputProps.value}
                                            targetData={targetData}
                                            caseData={caseData}
                                            indicators={buildIndicators(
                                                item.id,
                                            )}
                                        />
                                    );
                                case 'TargetCompany':
                                    return (
                                        <CompanyCard
                                            key={item.id}
                                            data={item}
                                            query={searchInputProps.value}
                                            targetData={targetData}
                                            caseData={caseData}
                                        />
                                    );
                            }
                        })}
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta?.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
