import React, { FC, useMemo, useState } from 'react';
import { ResultsProps } from '../../../Results';
import { ProfileInfo } from '../../../RiskAnalysisV2/ProfileInfo';
import { DefaultPageLayout } from '../../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { Checkbox, Paragraph } from '_atoms';
import {
    buildChartConfig,
    filterExperience,
    getAverageTimespanInMonthsOfPositions,
} from './helpers';
import { TargetReportSourceType } from '@indicium/common/src/types/Report/SharedTypes';
import { CvCard, CVCardSkeleton } from './Components/CvCard';
import { format, parseISO } from 'date-fns';
import { ALL_SOURCES, handleSourceChange } from '../helpers';
import { HeaderMessage } from './Components/HelperComponents';

export const CvStatistics: FC<ResultsProps> = ({
    targetData,
    targetImages,
    targetLoading,
}) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const [activeSources, setActiveSources] =
        useState<TargetReportSourceType[]>(ALL_SOURCES);

    const targetCreationDate = targetData?.createdAt
        ? format(parseISO(targetData.createdAt), 'yyyy-MM-dd')
        : undefined;

    const workExperienceBySource = Object.entries(
        targetData?.entrepreneurialActivities ?? {},
    )
        .filter(([source]) =>
            activeSources.includes(source as TargetReportSourceType),
        )
        .flatMap(([, workExperience]) => workExperience);

    const workExperience = workExperienceBySource
        .map((workExperience) => ({
            companyName: workExperience.companyName,
            role: workExperience.role ?? 'unknown',
            start: workExperience.from,
            end: workExperience.to ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const eductionBySource =
        targetData?.profile?.data.educations?.filter((education) =>
            education.sources.some((source) => activeSources.includes(source)),
        ) ?? [];

    const education = eductionBySource
        .map((education) => ({
            start: education.value.date_range?.start,
            end: education.value.date_range?.end ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const uniqueCompanies = Array.from(
        new Set(workExperienceBySource.flatMap((work) => work.companyName)),
    );

    const averageTimespanInMonthsOfPosition =
        getAverageTimespanInMonthsOfPositions(workExperience);

    const missingWorkRanges =
        workExperience.length !== workExperienceBySource.length;

    const missingEducationRanges = education.length !== eductionBySource.length;

    const chartConfig = useMemo(
        () =>
            buildChartConfig(
                averageTimespanInMonthsOfPosition,
                education,
                workExperience,
                uniqueCompanies,
                missingWorkRanges,
                missingEducationRanges,
                t,
            ),
        [
            averageTimespanInMonthsOfPosition,
            education,
            workExperience,
            uniqueCompanies,
            missingWorkRanges,
            missingEducationRanges,
            t,
        ],
    );

    const changeSource = (value: TargetReportSourceType[]) => {
        setActiveSources(value);
    };
    return (
        <DefaultPageLayout
            title={t('cVAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <HeaderMessage />
            <div className="pb-2 pt-5">
                <div>
                    <Paragraph
                        color="light"
                        weight="bold"
                        size="small"
                        className="font-jost"
                    >
                        {t('cVAnalysis.filters.selectSourceGroup')}
                    </Paragraph>
                    <div className="flex gap-4 py-3.5">
                        <Checkbox
                            onChange={(isSelected) =>
                                handleSourceChange(
                                    activeSources,
                                    'userInput',
                                    isSelected,
                                    changeSource,
                                )
                            }
                            initialIsSelected={activeSources.includes(
                                'userInput',
                            )}
                            centered={true}
                            className="font-normal"
                            size={5}
                            label={t(
                                `cVAnalysis.filters.sourceGroupOptions.cv`,
                            )}
                            labelPosition={'append'}
                        />
                        <Checkbox
                            onChange={(isSelected) =>
                                handleSourceChange(
                                    activeSources,
                                    'osint',
                                    isSelected,
                                    changeSource,
                                )
                            }
                            initialIsSelected={activeSources.includes('orbis')}
                            centered={true}
                            className="font-normal"
                            size={5}
                            label={t(
                                `cVAnalysis.filters.sourceGroupOptions.osint`,
                            )}
                            labelPosition={'append'}
                        />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-3 gap-4 overflow-visible">
                {chartConfig.map((statistic) => (
                    <CvCard key={statistic.labels.title} {...statistic} />
                ))}
                {targetLoading &&
                    Array(4)
                        .fill(null)
                        .map((_, index) => (
                            <CVCardSkeleton key={index} index={index} />
                        ))}
            </div>
        </DefaultPageLayout>
    );
};
