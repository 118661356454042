export enum EmailPreference {
    AllInOrg = 'allInOrg',
    OwnReports = 'ownReports',
    None = 'none',
}

export type UserData = {
    id?: string;
    email: string;
    title?: string;
    gender?: string;
    firstname?: string;
    lastname?: string;
    cases?: number;
    isAdmin?: boolean;
    privilegeLevel?: 'CustomerUser' | 'CustomerAdmin' | 'CustomerOwner';
    emailPreference: EmailPreference;
    reports?: number;
    createdAt?: string;
};

export type UpdateUserData = Pick<
    UserData,
    'firstname' | 'lastname' | 'emailPreference'
>;

export type UserDataWithStats = UserData & {
    activeAssignedCases: number;
    activeCreatedTargets: number;
    totalTargets: number;
};
