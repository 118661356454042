import { FC, Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Error, LoadingSpinner } from '_atoms';
import { Alert, InfiniteScrollV2, SearchInput } from '_molecules';
import { ResultsProps } from '../../../features/dashboard/Results';
import type { SocialAccountPostArticle } from '../../../services/searchService';
import { SearchFilterValue } from '_types';
import socialMediaPostsFilters from './SocialMediaPostsFilters.json';
import { nonProdDataTestId } from '_utils';
import { SocialMediaSmallCard } from '_organisms/SocialMedia/SocialMediaSmallCard';
import { EntityIndicator } from '../../../hooks/queries/useTargetIndicatorsQuery';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';

type SocialMediaPostsFilters = {
    publishedAt: SearchFilterValue;
    language: SearchFilterValue;
};

type SocialMediaPostsProps = Pick<
    ResultsProps,
    'accessToken' | 'targetData' | 'caseData' | 'entityIndicators'
>;

export const SocialMediaPosts: FC<SocialMediaPostsProps> = ({
    targetData,
    caseData,
    entityIndicators,
}) => {
    const { t } = useTranslation();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<SocialMediaPostsFilters>({
            filters: socialMediaPostsFilters,
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'socialMediaPost',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    return (
        <Fragment>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>

            <div
                className="relative mt-5 z-10"
                data-testid={nonProdDataTestId('socialmediaposts')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles?.length ? (
                    <div>
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 items-stretch my-3 w-full">
                            {articles.map((item) => (
                                <SocialMediaSmallCard
                                    key={item.id}
                                    data={item as SocialAccountPostArticle}
                                    query={searchInputProps.value}
                                    hideArticleType={true}
                                    caseData={caseData}
                                    targetData={targetData}
                                    indicators={entityIndicators
                                        .filter(
                                            (e: EntityIndicator) =>
                                                e.entityId === item.id,
                                        )
                                        .flatMap(
                                            ({ indicators }) => indicators,
                                        )}
                                />
                            ))}
                        </div>
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </div>
                ) : isLoading ? null : (
                    <Alert alertType="info" message={t('noData')} />
                )}
            </div>
        </Fragment>
    );
};
