import { FC } from 'react';
import { Button, Modal, Paragraph, RadioGroup } from '_atoms';
import { nonProdDataTestId } from '_utils';
import { useTranslation } from 'react-i18next';

type ConfirmationModalProps = {
    body?: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    enableConfirm?: boolean;
    isOpen: boolean;
    radioOptions?: {
        options: string[];
        handleSelect: (value: string) => void;
    };
    title?: string;
    handleButtonClick: (event: 'confirm' | 'cancel') => void;
    hideCloseButton?: boolean;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
    body,
    cancelButtonText,
    confirmButtonText,
    enableConfirm = true,
    isOpen,
    radioOptions,
    title,
    handleButtonClick,
    hideCloseButton,
}: ConfirmationModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            title={title ?? t('confirmationModal.defaultTitle')}
            data-testid={nonProdDataTestId('popup2')}
            hideCloseButton={hideCloseButton}
        >
            <Paragraph className="mt-4">
                {body ?? t('confirmationModal.defaultBody')}
            </Paragraph>
            {radioOptions && (
                <RadioGroup
                    className="mt-2 items-center"
                    options={radioOptions.options.map((label, optionIdx) => ({
                        label,
                        value: `delete-option-${optionIdx}`,
                    }))}
                    onSelect={radioOptions.handleSelect}
                />
            )}
            <div className="flex justify-end space-x-6 mt-6">
                <Button
                    level="primaryGhost"
                    type="button"
                    onClick={() => handleButtonClick('cancel')}
                    data-testid={nonProdDataTestId('cancel delete button')}
                >
                    {cancelButtonText ?? t('cancel')}
                </Button>
                <Button
                    disabled={
                        enableConfirm !== undefined ? !enableConfirm : true
                    }
                    type="button"
                    level="error"
                    onClick={() => handleButtonClick('confirm')}
                    data-testid={nonProdDataTestId('confirm delete button')}
                >
                    {confirmButtonText ?? t('confirm')}
                </Button>
            </div>
        </Modal>
    );
};
