import React, { FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import classnames from 'classnames';
import { Experience, TimelineFilters } from '../../types';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { useTranslation } from 'react-i18next';
import { TooltipContentV2, TooltipTriggerV2, TooltipV2 } from '_atoms';

interface TimelineProps {
    data: Experience[];
    gaps?: { start: Date; end: Date }[];
    filters: TimelineFilters;
    rows: Experience[][];
    range: { minDate: Date; maxDate: Date };
}

export const HorizontalTimeline: FC<TimelineProps> = ({
    data,
    gaps,
    filters,
    rows,
    range,
}) => {
    const { t, i18n } = useTranslation();

    const [chartWidth, setChartWidth] = useState(0);

    const [popup, setPopup] =
        useState<{ experience: Experience; rowIndex: number } | null>(null);

    useEffect(() => {
        setPopup(null);
    }, [filters]);

    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement | null>(null);

    const fixedContainerHeight = 350;
    const rowHeight = 120;
    const chartMargin = { top: 0, right: 30, bottom: 0, left: 30 };

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setChartWidth(
                    containerRef.current.offsetWidth -
                        chartMargin.left -
                        chartMargin.right,
                );
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [chartMargin.left, chartMargin.right]);

    const totalHeight = rows.length * rowHeight;

    const xScale = d3
        .scaleTime()
        .domain([range.minDate, range.maxDate])
        .range([chartMargin.left, chartWidth - chartMargin.right]);

    const yScale = d3
        .scaleBand<number>()
        .domain(d3.range(rows.length))
        .range([50, totalHeight])
        .padding(0.5);

    const colorScale = d3
        .scaleOrdinal<string>()
        .domain(['education', 'work'])
        .range(['#8ED2C6', '#2CA4AB']); // contrast-3 and contrast-2

    const xAxisOutside = d3.axisBottom(xScale);

    const handleBarClick = (experience: Experience, rowIndex: number) => {
        setPopup(
            popup?.experience.header === experience.header
                ? null
                : { experience, rowIndex },
        );
    };

    const maxBarWidth = 150;
    const rightEdge = xScale(xScale.domain()[1]);

    const hasEducation = data.some((d) => d.type === 'education');
    const hasWorkExperience = data.some((d) => d.type === 'work');

    const renderRangeTextAndLines = (
        date: Date,
        rowIndex: number,
        type: 'start' | 'end',
    ) => {
        return (
            <g>
                <line
                    x1={xScale(date) + (type === 'start' ? 0.5 : -0.5)}
                    y1={0}
                    x2={xScale(date) + (type === 'start' ? 0.5 : -0.5)}
                    y2={(yScale(rowIndex) as number) + 7}
                    stroke={colorScale('work') as string}
                    strokeWidth={0.5}
                />
                <rect
                    x={xScale(date) - (type === 'start' ? 45.5 : 0)}
                    y={(yScale(rowIndex) as number) + 1}
                    width={45}
                    height={12}
                    fill="white"
                    rx={6}
                />
                <text
                    x={xScale(date) - (type === 'start' ? 6 : -4)}
                    y={(yScale(rowIndex) as number) + 10}
                    className="text-xs"
                    fontWeight={700}
                    fill={colorScale('work') as string}
                    textAnchor={type === 'start' ? 'end' : 'start'}
                >
                    {date.toLocaleDateString(i18n.language, {
                        month: 'short',
                        year: 'numeric',
                    })}
                </text>
            </g>
        );
    };

    const renderPopupContent = (experience: Experience) => {
        return (
            <div className="flex flex-col gap-2 p-2 text-sm">
                <span className="font-bold">{experience.header}</span>
                <span>{experience.subHeader}</span>
                <div>
                    {experience.start.toLocaleDateString(i18n.language, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    })}{' '}
                    -{' '}
                    {experience.end.toLocaleDateString(i18n.language, {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    })}
                </div>
                <span className="text-neutral-450">
                    {experience.sources
                        .map((s) => t(`sourceName.${s}`))
                        .join(', ')}
                </span>
            </div>
        );
    };

    if (data.length === 0) {
        return (
            <TableEmpty
                className="p-4 pb-8 rounded-lg bg-neutral-50"
                imageHeight={132}
                message={t('cVAnalysis.noTimelineHeadline')}
            />
        );
    }

    return (
        <div className="w-full">
            <div
                className="relative rounded-lg bg-white p-2"
                ref={containerRef}
            >
                <div
                    className={classnames('relative rounded-t-lg')}
                    style={{
                        height: `${25}px`,
                        width: '100%',
                    }}
                >
                    {/* Sticky X-Axis */}
                    <svg
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 0,
                        }}
                        width={chartWidth}
                    >
                        <g
                            ref={(node) => {
                                if (node) {
                                    d3.select(node).call(xAxisOutside);
                                }
                            }}
                        />
                    </svg>
                </div>
                <div
                    className={classnames(
                        'relative overflow-auto rounded-b-lg',
                    )}
                    style={{
                        height: `${fixedContainerHeight}px`,
                        width: '100%',
                    }}
                >
                    <svg
                        ref={svgRef}
                        width={chartWidth}
                        height={Math.max(totalHeight, fixedContainerHeight)}
                        className="rounded-lg"
                    >
                        <g id={'gridLines'}>
                            {xScale.ticks().map((d) => (
                                <g
                                    key={d.toString()}
                                    opacity={popup ? '0.5' : '1'}
                                >
                                    <line
                                        x1={xScale(d)}
                                        y1={0}
                                        x2={xScale(d)}
                                        y2={Math.max(
                                            totalHeight,
                                            fixedContainerHeight,
                                        )}
                                        stroke="#D5D5D5"
                                        strokeWidth={0.5}
                                    />
                                </g>
                            ))}
                        </g>
                        <g id={'gaps'}>
                            {filters.showGaps &&
                                gaps?.map((gap, i) => (
                                    <rect
                                        key={i}
                                        x={xScale(gap.start)}
                                        y={0}
                                        width={
                                            xScale(gap.end) - xScale(gap.start)
                                        }
                                        height={totalHeight}
                                        fill="#F98787"
                                        opacity={0.5}
                                    />
                                ))}
                        </g>
                        <g id={'bars'}>
                            {rows.map((row, rowIndex) =>
                                row.map((experience, i) => {
                                    const nextExperience =
                                        i < row.length - 1 ? row[i + 1] : null;

                                    const currBarWidth = Math.floor(
                                        xScale(experience.end) -
                                            xScale(experience.start),
                                    );
                                    const prevBarTooSmall =
                                        i !== 0 &&
                                        xScale(experience.start) -
                                            xScale(row[i - 1]?.start) <
                                            maxBarWidth;

                                    const currTextBelow = prevBarTooSmall
                                        ? i % 2 !== 0
                                        : false;

                                    const nextTextBelow =
                                        currBarWidth < maxBarWidth &&
                                        (i + 1) % 2 !== 0;

                                    const availableSpace = !nextExperience
                                        ? Math.floor(
                                              rightEdge -
                                                  xScale(experience.start),
                                          )
                                        : currTextBelow === nextTextBelow
                                        ? xScale(nextExperience.start) -
                                          xScale(experience.start)
                                        : xScale(nextExperience.end) -
                                          xScale(experience.start);

                                    const maxTextWidth = Math.max(
                                        currBarWidth,
                                        Math.floor(availableSpace),
                                    );

                                    const headerText = experience.header;

                                    const subHeaderText = `${
                                        experience.subHeader
                                    } (${Math.round(
                                        (experience.end.getTime() -
                                            experience.start.getTime()) /
                                            (1000 * 60 * 60 * 24 * 30),
                                    )} months)`;

                                    const prepareText = (
                                        text: string,
                                        isBold: boolean,
                                    ) => {
                                        // TODO: find a better way to calculate the width of the full text
                                        const averagePixelsPerLetter = isBold
                                            ? 8.5
                                            : 8;

                                        return text.length *
                                            averagePixelsPerLetter >
                                            maxTextWidth
                                            ? `${text.substring(
                                                  0,
                                                  Math.floor(
                                                      maxTextWidth /
                                                          averagePixelsPerLetter,
                                                  ),
                                              )}...`
                                            : text;
                                    };

                                    return (
                                        <TooltipV2
                                            disabled={
                                                popup?.experience !== experience
                                            }
                                            withArrow={true}
                                            open={
                                                popup?.experience === experience
                                            }
                                            key={`${rowIndex}-${i}`}
                                        >
                                            <TooltipTriggerV2 asChild={true}>
                                                <g
                                                    onClick={() =>
                                                        handleBarClick(
                                                            experience,
                                                            rowIndex,
                                                        )
                                                    }
                                                >
                                                    <rect
                                                        x={xScale(
                                                            experience.start,
                                                        )}
                                                        y={
                                                            yScale(
                                                                rowIndex,
                                                            ) as number
                                                        }
                                                        width={currBarWidth}
                                                        height={14}
                                                        rx={8}
                                                        fill={
                                                            colorScale(
                                                                experience.type,
                                                            ) as string
                                                        }
                                                        opacity={
                                                            popup &&
                                                            popup.experience !==
                                                                experience
                                                                ? 0.2
                                                                : 1
                                                        }
                                                        className="cursor-pointer"
                                                    />
                                                    <g
                                                        className={
                                                            popup &&
                                                            popup.experience ===
                                                                experience
                                                                ? ''
                                                                : ''
                                                        }
                                                        opacity={
                                                            popup &&
                                                            popup.experience !==
                                                                experience
                                                                ? 0.2
                                                                : 1
                                                        }
                                                    >
                                                        <text
                                                            x={
                                                                xScale(
                                                                    experience.start,
                                                                ) + 3
                                                            }
                                                            y={
                                                                currTextBelow
                                                                    ? (yScale(
                                                                          rowIndex,
                                                                      ) as number) +
                                                                      30 // Position text below the bar
                                                                    : (yScale(
                                                                          rowIndex,
                                                                      ) as number) -
                                                                      24 // Default position above the bar
                                                            }
                                                            width={60}
                                                            className="text-white text-sm font-bold"
                                                        >
                                                            <title>
                                                                {headerText}
                                                            </title>
                                                            {prepareText(
                                                                headerText,
                                                                true,
                                                            )}
                                                        </text>
                                                        <text
                                                            x={
                                                                xScale(
                                                                    experience.start,
                                                                ) + 3
                                                            }
                                                            y={
                                                                currTextBelow
                                                                    ? (yScale(
                                                                          rowIndex,
                                                                      ) as number) +
                                                                      44 // Position text below
                                                                    : (yScale(
                                                                          rowIndex,
                                                                      ) as number) -
                                                                      10 // Default position above
                                                            }
                                                            width={60}
                                                            className="text-white text-sm"
                                                        >
                                                            <title>
                                                                {subHeaderText}
                                                            </title>
                                                            {prepareText(
                                                                subHeaderText,
                                                                false,
                                                            )}
                                                        </text>
                                                    </g>
                                                </g>
                                            </TooltipTriggerV2>
                                            <TooltipContentV2 className="max-w-80">
                                                {renderPopupContent(experience)}
                                            </TooltipContentV2>
                                        </TooltipV2>
                                    );
                                }),
                            )}
                        </g>
                        {popup && (
                            <g>
                                {renderRangeTextAndLines(
                                    popup.experience.start,
                                    popup.rowIndex,
                                    'start',
                                )}
                                {renderRangeTextAndLines(
                                    popup.experience.end,
                                    popup.rowIndex,
                                    'end',
                                )}
                            </g>
                        )}
                    </svg>
                </div>
                <div
                    className={classnames(
                        'flex gap-6 items-center text-sm pt-2 pl-2 border-t',
                    )}
                >
                    <span className="font-semibold">
                        {t('cVAnalysis.legend')}
                    </span>
                    {hasEducation && (
                        <div className="flex flex-row gap-1">
                            <div className="rounded-full bg-contrast-3 w-6 h-4" />
                            <span>{t('cVAnalysis.education')} </span>
                        </div>
                    )}
                    {hasWorkExperience && (
                        <div className="flex flex-row gap-1">
                            <div className="rounded-full bg-contrast-2 w-6 h-4" />
                            <span>{t('cVAnalysis.work')}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
