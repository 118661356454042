import { FC, useCallback, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '_atoms';
import { EmailPreference, UserData } from '../../../../types/UserData';

export type NewUser = {
    name: string | null | undefined;
    firstName: string | null | undefined;
    email: string | undefined;
    gender: string | null | undefined;
};

type TableRowInputProps = {
    unedited: UserData;
    genderOptions: string[];
    onAddNewInfo: (event: UserData) => void;
    className?: string;
};

export const TableRowInput: FC<TableRowInputProps> = ({
    unedited,
    genderOptions,
    onAddNewInfo,
    className,
}: TableRowInputProps) => {
    const { t } = useTranslation();
    const [newLastname, setNewLastname] = useState(unedited.lastname);
    const [newFirstName, setNewFirstname] = useState(unedited.firstname);
    const [newEmail, setNewEmail] = useState(unedited.email);
    const [newGender, setNewGender] = useState(unedited.gender);

    const handleAddNew = useCallback(() => {
        onAddNewInfo({
            lastname: newLastname,
            firstname: newFirstName,
            email: newEmail,
            gender: newGender
                ? newGender
                : `${
                      t('genders', {
                          returnObjects: true,
                      })[0]
                  }`,
            emailPreference: EmailPreference.OwnReports,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newEmail, newFirstName, newLastname, newGender]);

    return (
        <tr
            className={classnames(
                className,
                `bg-neutral-100 text-gray-900 flex justify-between ${
                    newEmail === 'null'
                        ? 'items-end'
                        : 'items-center w-full absolute z-30 py-3'
                } px-6 space-x-2 md:space-x-6`,
            )}
        >
            <td className="w-full">
                <Input
                    onChange={(value) => {
                        setNewLastname(value.target.value);
                    }}
                    value={newLastname ? newLastname : ''}
                    placeholder={newLastname ? '' : `Name`}
                />
            </td>

            <td className="w-full">
                <Input
                    onChange={(value) => {
                        setNewFirstname(value.target.value);
                    }}
                    value={newFirstName ? newFirstName : ''}
                    placeholder={newFirstName ? '' : `Vorname`}
                />
            </td>

            <td className="w-full">
                <Input
                    onChange={(value) => {
                        setNewEmail(value.target.value);
                    }}
                    value={newEmail === 'null' ? '' : newEmail}
                    placeholder={
                        newEmail === 'null' ? 'beispiel@email.com' : newEmail
                    }
                    required={newEmail === 'null' ? true : false}
                />
            </td>

            <td>
                <Select
                    className="w-28"
                    initialSelection={{ id: 1, label: '-', value: undefined }}
                    options={Object.values(genderOptions).map(
                        (title, index) => ({
                            id: index,
                            label: title,
                            value: title,
                        }),
                    )}
                    onChange={(newValue) => {
                        if (newValue) {
                            setNewGender(newValue);
                        }
                    }}
                />
            </td>
            <td>
                {/* @NOTE: Choosing not to use the Button atom for styling reasons */}
                <button
                    onClick={() => handleAddNew()}
                    className="bg-primary-4 border border-transparent disabled:bg-neutral-300 disabled:cursor-default focus:outline-none focus:ring-2 focus:ring-primary-4 focus:ring-offset-2 font-medium hover:bg-primary-3 inline-flex items-center justify-center leading-5 mt-1 px-5 py-2 rounded-md shadow-sm text-base text-font-light"
                >
                    {t('addButton')}
                </button>
            </td>
        </tr>
    );
};
