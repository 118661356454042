import { FC, Fragment, useCallback, useMemo } from 'react';
import {
    Alert,
    InfiniteScrollV2,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { ResultsProps } from '../Results';
import { ImageItem } from './ImageItem';
import { useParams } from 'react-router-dom';
import { SearchFilterValue } from '_types';
import { Error, LoadingSpinner } from '_atoms';
import { ImageArticle } from '../../../services/searchService';
import { useTranslation } from 'react-i18next';

import imagesFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';

type ImagesFilters = {
    createdDate: SearchFilterValue;
    imageSources: SearchFilterValue;
};

export type ImagesProps = ResultsProps & { accessToken: string };

export const Images: FC<ImagesProps> = ({ targetData }: ImagesProps) => {
    const { t } = useTranslation();

    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<ImagesFilters>({
            filters: imagesFilters,
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'images',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
        pageSize: 36,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    return (
        <DefaultPageLayout title={t('rawData.imageSlashVideos')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>

            <WorkflowError errors={targetData?.workflowErrors} path="profile" />
            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}

            <div
                className="relative mt-5 z-10"
                data-testid={nonProdDataTestId('images')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full z-0">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <Fragment>
                        <div className="grid grid-cols-2 2xl:grid-cols-6 md:grid-cols-4 gap-5 pt-5 pb-10">
                            {articles?.map((item, index) => (
                                <ImageItem
                                    key={index}
                                    {...(item as ImageArticle)}
                                    onTagClicked={(query) =>
                                        searchInputProps.onSearchInput(query)
                                    }
                                    query={searchInputProps.value}
                                />
                            ))}
                        </div>
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </Fragment>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
