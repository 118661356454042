import React, { FC } from 'react';
import classnames from 'classnames';
import {
    Paragraph,
    TooltipContentV2,
    TooltipTriggerV2,
    TooltipV2,
} from '_atoms';
import { IoMdInformationCircleOutline } from 'react-icons/io';

type RadioGroupLabel = {
    label: string;
    tooltip?: string;
};

type RadioGroupOption = {
    label: string;
    value: string;
    tooltip?: string;
};

type RadioGroupProps = {
    groupLabel?: RadioGroupLabel;
    className?: string;
    options: RadioGroupOption[];
    selected?: string;
    onSelect?: (value: string) => void;
    disabled?: boolean;
    direction?: 'horizontal' | 'vertical';
};

const ToolTip: FC<{
    label: string;
    tooltip: string;
    triggerClassName?: string;
}> = ({ label, tooltip, triggerClassName }) => {
    return (
        <TooltipV2>
            <TooltipContentV2 className="whitespace-pre-line max-w-sm">
                {tooltip}
            </TooltipContentV2>
            <TooltipTriggerV2
                asChild
                className={classnames(triggerClassName, 'flex items-center')}
            >
                {label}

                <IoMdInformationCircleOutline className="ml-1" size={14} />
            </TooltipTriggerV2>
        </TooltipV2>
    );
};

export const RadioGroup: FC<RadioGroupProps> = ({
    groupLabel,
    className,
    options,
    selected,
    onSelect,
    disabled = false,
    direction = 'horizontal',
}: RadioGroupProps) => {
    return (
        <>
            {groupLabel && (
                <label className="block text-sm font-bold font-jost text-neutral-500">
                    {groupLabel?.tooltip ? (
                        <ToolTip
                            label={groupLabel.label}
                            tooltip={groupLabel.tooltip}
                        />
                    ) : (
                        <>{groupLabel.label}</>
                    )}
                </label>
            )}
            <div
                id={groupLabel?.label ?? ''}
                className={classnames(
                    'flex flex-row gap-5',
                    className,
                    direction === 'vertical' && 'flex-col',
                    disabled && 'pointer-events-none opacity-50',
                )}
            >
                {options.map(({ label, value, tooltip }, index) => (
                    <div
                        className={classnames(
                            'flex flex-row items-center gap-2 cursor-pointer',
                        )}
                        key={index}
                        onClick={() => {
                            if (onSelect && !disabled) {
                                onSelect(value);
                            }
                        }}
                    >
                        <span
                            className={classnames(
                                'flex items-center justify-center flex-shrink-0 h-4 w-4 cursor-pointer rounded-full',
                                selected === value
                                    ? 'bg-primary-4'
                                    : 'bg-neutral-50 border border-neutral-400',
                            )}
                        >
                            <span
                                className={classnames(
                                    'rounded-full bg-neutral-200 w-1 h-1',
                                    selected === value
                                        ? ''
                                        : 'border border-neutral-400',
                                )}
                            />
                        </span>
                        {tooltip ? (
                            <ToolTip
                                label={label}
                                tooltip={tooltip}
                                triggerClassName={
                                    selected === value
                                        ? 'text-primary-4'
                                        : 'text-neutral-500'
                                }
                            />
                        ) : (
                            <Paragraph
                                color={selected === value ? 'blue' : 'light'}
                            >
                                {label}
                            </Paragraph>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};
