import { FC, useCallback, useMemo } from 'react';
import {
    Alert,
    InfiniteScrollV2,
    SearchInput,
    WorkflowError,
} from '_molecules';
import { ResultsProps } from '../Results';
import { Error, LoadingSpinner } from '_atoms';
import { SearchResultCard } from '_organisms';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchFilterValue } from '_types';
import { SearchEngineArticle } from 'src/services/searchService';

import searchEngineFilters from './filters.json';
import { nonProdDataTestId } from '_utils';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useRawDataFilters } from '../../../hooks/useRawDataFilters';
import { useElasticSearchQuery } from '../../../hooks/useElasticSearchQuery';

type SearchEngineFilters = {
    source: SearchFilterValue;
    createdDate: SearchFilterValue;
};

export type SearchEnginesProps = ResultsProps & { accessToken: string };

// This is the component rendering the contents of the Search Engines tab in the raw data section of a report
export const SearchEngines: FC<SearchEnginesProps> = ({
    targetData,
    caseData,
    entityIndicators,
}: SearchEnginesProps) => {
    const { t } = useTranslation();
    const { caseId, targetId } =
        useParams<{
            caseId: string;
            targetId: string;
        }>();

    const { renderedFilters, filtersState, ...searchInputProps } =
        useRawDataFilters<SearchEngineFilters>({
            filters: searchEngineFilters,
        });

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isLoading,
        isError,
    } = useElasticSearchQuery({
        caseId,
        targetId,
        entityType: 'WebPage',
        exactMatch: filtersState.exactMatch,
        filters: filtersState.filters,
        query: searchInputProps.value,
    });

    const articles = useMemo(
        () => data?.pages.flatMap((page) => page.items) ?? [],
        [data],
    );

    const articleMeta = data?.pages.at(-1);

    const handlePageChange = useCallback(() => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, fetchNextPage]);

    const buildIndicators = (itemId: string) =>
        entityIndicators
            .filter((s) => s.entityId === itemId)
            .flatMap(({ indicators }) => indicators);

    return (
        <DefaultPageLayout title={t('rawData.searchEngines')}>
            <SearchInput {...searchInputProps}>{renderedFilters}</SearchInput>
            <WorkflowError errors={targetData?.workflowErrors} path="profile" />

            {targetData?.status === 'HasInitialProfile' && (
                <Alert
                    alertType="warning"
                    headline={t('profileDataNotFinal')}
                    className="mb-2"
                />
            )}

            <div
                className="relative mt-6 z-10 flex flex-col gap-4"
                data-testid={nonProdDataTestId('search-engines')}
            >
                {isLoading ? (
                    <div className="absolute top-0 flex justify-center w-full py-20">
                        <LoadingSpinner message={t('profileLoading')} />
                    </div>
                ) : isError ? (
                    <Error
                        headline={t('profileErrorHeadline')}
                        message={t('profileErrorRetryMessage')}
                    />
                ) : null}
                {articles.length ? (
                    <>
                        {articles?.map((item) => (
                            <SearchResultCard
                                key={(item as SearchEngineArticle).url}
                                data={item as SearchEngineArticle}
                                query={searchInputProps.value}
                                hideArticleType={true}
                                targetData={targetData}
                                caseData={caseData}
                                indicators={buildIndicators(item.id)}
                            />
                        ))}
                        {articleMeta?.paging && (
                            <InfiniteScrollV2
                                paging={articleMeta.paging}
                                isLoading={isFetchingNextPage}
                                setPageNumber={handlePageChange}
                            />
                        )}
                    </>
                ) : isLoading ? null : (
                    <Alert
                        alertType="info"
                        message={t('noData')}
                        className="mb-5"
                    />
                )}
            </div>
        </DefaultPageLayout>
    );
};
